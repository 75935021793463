import React from "react";
import { Page, LoadingText } from "./styles";

const FullPageLoad = () => {
  return (
    <Page>
      <LoadingText>Loading...</LoadingText>
    </Page>
  );
};

export default FullPageLoad;
