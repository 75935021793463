import React from "react";
import {
  Card,
  IconParent,
  IconContainer,
  CardContent,
  LinkTitle,
  IconImg,
} from "./styles";
import selectIcon from "../../utils/iconSelector";
import { LinkPreviewProps } from "./types";

const LinkPreview: React.FC<LinkPreviewProps> = ({
  type,
  link,
  linkText,
  title,
}) => {
  return (
    <Card link={link}>
      <IconParent>
        <IconContainer>
          <IconImg src={selectIcon(type)} />
        </IconContainer>
      </IconParent>
      <CardContent>
        <LinkTitle linkText={linkText}>{title}</LinkTitle>
      </CardContent>
    </Card>
  );
};

export default LinkPreview;
