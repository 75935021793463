import React from "react";
import styled from "styled-components";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  Title,
  SubTitle,
  ButtonFlex,
  BackButton,
} from "../Signup/styles";
import Button from "../../components/Button";
import { motion } from "framer-motion";
import { pageTransition } from "../animations/variants";
import { spring } from "../animations/config";
import WhiteTextField from "../../components/WhiteTextField";
import { Redirect, Link } from "react-router-dom";
import FetchService from "../../utils/fetchService";
import { useUserState } from "../../context/UserContext";

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  width: 100%;
`;

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 50px 40px;
  background-color: ${(props) => props.theme.colors.background};
`;

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const ForgotPassword = () => {
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const userState = useUserState();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
      setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    setError(false);
    setEmailError(false);
    setLoading(true);
    const emailValidated = RegExp(emailRegex).test(email);
    if (emailValidated) {
      const fetchServiceInstance = new FetchService();
      const response = await fetchServiceInstance.post(
        "/auth/forget-password",
        {
          email,
        },
        userState?.jid
      );
      setLoading(false);
      if (response.errors) {
        setError(true);
      } else {
        setState("entered");
      }
    } else {
      setEmailError(true);
      setLoading(false);
    }
  };

  if (state !== "" && state !== "entered") return <Redirect to={state} />;
  else if (state === "entered")
    return (
      <Page>
        <PageContainer>
        <motion.div
            exit="out"
            animate="in"
            initial="out"
            variants={pageTransition}
            transition={spring}
            custom={1}
          >
            <button onClick={() => setState("")}>
              <BackButton
                iconProps={{
                  icon: faAngleRight,
                  flip: "horizontal",
                  size: "lg",
                  color: "white",
                }}
              />
            </button>
              <Title>Your password request has been sent</Title>
              <SubTitle>If you didn't get the email, check your spam folder or {" "}
                <Link
                  style={{ color: "#11B8FF" }}
                  to={""}
                >
                  try again
                </Link>
                .
              </SubTitle>
          </motion.div>
        </PageContainer>
      </Page>
    );
  else
    return (
      <Page>
        <PageContainer>
          <motion.div
            exit="out"
            animate="in"
            initial="out"
            variants={pageTransition}
            transition={spring}
            custom={1}
          >
            <button onClick={() => setState("/account/login")}>
              <BackButton
                iconProps={{
                  icon: faAngleRight,
                  flip: "horizontal",
                  size: "lg",
                  color: "white",
                }}
              />
            </button>
            <form onSubmit={handleSubmit}>
              <Title>Forgot Password</Title>
              <SubTitle>Enter your email address below.</SubTitle>
              <WhiteTextField
                id="email"
                label="Email"
                type="text"
                fullWidth
                size="small"
                onChange={(event) => handleInputChange(event)}
                error={emailError || error}
                helperText={error ? "There was an error resetting your password or an account with this email does not exist." : emailError ? "Invalid email address." : ""}
              />
              <ButtonFlex>
                <Button loading={loading}>
                  Reset Password
                </Button>
              </ButtonFlex>
            </form>
          </motion.div>
        </PageContainer>
      </Page>
    );
};

export default ForgotPassword;
