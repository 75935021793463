import React from "react";
import styled from "styled-components";
import { AddLinkProps, LinkType } from "./types";
import BottomModal from "../../components/BottomModal";
import selectIcon from "../../utils/iconSelector";
import LinkChange from "../../components/LinkChange";
import { getLabel, getLinkInstruction, getName } from "./linkHelper";

const Container = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-top: 20px;
  overflow-y: scroll;
`;

const LinkButton = styled.img`
  width: 45px;
  height: 45px;
  margin: 10px;
  cursor: pointer;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinkTitle = styled.p`
  font-size: 0.7rem;
`;

const linkTypeList: LinkType[] = [
  "instagram",
  "twitter",
  "tiktok",
  "snapchat",
  "facebook",
  "linkedin",
  "address",
  "website",
  "custom",
  "twitch",
  "whatsapp",
  "paypal",
  "cashapp",
  "venmo",
  "applemusic",
  "spotify",
  "soundcloud",
  "youtube",
  "email",
  "github",
  "text",
  "contact",
];

const AddLink: React.FC<AddLinkProps> = ({
  open,
  onBackDropClick,
  onComplete,
}) => {
  const [linkType, setLinkType] = React.useState<LinkType | null>(null);
  return (
    <BottomModal
      title="Add new link"
      subtitle={getLinkInstruction(linkType || "custom")}
      open={open}
      onBackdropClick={() => {
        onBackDropClick();
        setLinkType(null);
      }}
    >
      <Container>
        {linkType === null ? (
          linkTypeList.map((link) => (
            <LinkContainer>
              <LinkButton
                src={selectIcon(link)}
                onClick={() => setLinkType(link)}
                key={link}
              />
              <LinkTitle>{getName(link)}</LinkTitle>
            </LinkContainer>
          ))
        ) : (
          <LinkChange
            type={linkType}
            job="create"
            id={0}
            label={getLabel(linkType)}
            order={0}
            onComplete={(link) => {
              onComplete(link);
              setLinkType(null);
            }}
          />
        )}
      </Container>
    </BottomModal>
  );
};

export default AddLink;
