import React from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Title, SubTitle, ButtonFlex, BackButton, Page } from "../styles";
import styled from "styled-components";
import Button from "../../../components/Button";
import { Redirect } from "react-router-dom";
import FetchService from "../../../utils/fetchService";
import { useUserState, useUserDispatch } from "../../../context/UserContext";
import WhiteTextField from "../../../components/WhiteTextField";

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 30px;
`;

// eslint-disable-next-line no-control-regex
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const Email: React.FC = () => {
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [availableError, setAvailableError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const userState = useUserState();
  const userDispatch = useUserDispatch();

  if (error && !availableError) {
    if (RegExp(emailRegex).test(email)) {
      setError(false);
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const emailValidated = RegExp(emailRegex).test(email);

    if (!emailValidated) {
      setError(true);
      setHelperText("Invalid email.");
      return;
    }

    const fetchServiceInstance = new FetchService();
    setError(false);

    /**
     * First check if the email is available.
     */
    setLoading(true);
    let response = await fetchServiceInstance.get("/auth/available", [
      { id: "email", value: email },
    ]);

    if (response.errors) {
      setAvailableError(true);
      setHelperText("We couldn't validate this email. Please try again later.");
      setLoading(false);
      return;
    } else if (!response.data.emailAvailable) {
      setAvailableError(true);
      setHelperText(
        "This emailis already being used. Please use a different one."
      );
      setLoading(false);
      return;
    }

    /**
     * The email must be available.
     * Send a put request to change the email.
     */
    response = await fetchServiceInstance.put(
      "/user/edit",
      {
        email,
      },
      userState?.jid
    );
    setLoading(false);

    if (response.errors) {
      setAvailableError(true);
      setHelperText(
        "There was an error changing your email. Please try again later."
      );
    } else {
      userDispatch({ type: "email", payload: email });
    }
    setState("/account/edit-profile");
  };
  if (state !== "") return <Redirect to={state} />;
  else
    return (
      <Page>
        {" "}
        <PageContainer>
          <button onClick={() => setState("/account/edit-profile")}>
            <BackButton
              iconProps={{ icon: faAngleRight, flip: "horizontal", size: "lg" }}
            />
          </button>
          <form onSubmit={handleSubmit}>
            <Title>Change your email</Title>
            <SubTitle>Enter your email below.</SubTitle>
            <WhiteTextField
              id="email"
              label="Email"
              type="text"
              fullWidth
              size="small"
              onChange={handleInputChange}
              error={error || availableError}
              helperText={error || availableError ? helperText : ""}
            />
            <ButtonFlex>
              <Button loading={loading}>Submit</Button>
            </ButtonFlex>
          </form>
        </PageContainer>
      </Page>
    );
};

export default Email;
