import React from "react";
import { useParams, Redirect } from "react-router-dom";
import FullPageLoad from "../../components/FullPageLoad";
import FetchService from "../../utils/fetchService";
import { useUserState } from "../../context/UserContext";

const Reroute = () => {
  const params = useParams() as { code: string };
  const [error, setError] = React.useState(false);
  const [reroute, setReroute] = React.useState("");
  const userState = useUserState();

  React.useEffect(() => {
    const fetchService = new FetchService();
    async function getCodeInfo() {
      const result = await fetchService.get(`/reroute/${params.code || ""}`);

      if (result.errors) {
        setError(true);
      } else {
        if (result.data.reroute.activated && result.data.reroute.username) {
          setReroute(`/${result.data.reroute.username}?code=${params.code}`);
        } else {
          if (userState) {
            setReroute(`/account/activate?code=${params.code}`);
          } else {
            setReroute(`/account/signup?code=${params.code}`);
          }
        }
      }
    }

    getCodeInfo();
  }, [params.code, userState]);

  if (error) return <Redirect to="/" />;

  if (reroute !== "") return <Redirect to={reroute} />;

  return <FullPageLoad />;
};

export default Reroute;
