import styled from "styled-components";
import { motion } from "framer-motion";

export const Card = styled(motion.div)`
  box-shadow: 0 0.25em 0.375em rgba(50, 50, 93, 0.09),
    0 0.263em 0.488em rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  height: 55px;
  overflow-y: hidden;
`;

export const Title = styled.h1`
  font-family: averta-regular;
  margin-right: 15px;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.background};
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  cursor: pointer;
`;

export const TitleArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColorBox = styled.div<{
  color: string;
  onClick?: (event: any) => void;
}>`
  width: 35px;
  min-width: 35px;
  height: 35px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #${(props) => props.color};
  cursor: pointer;
  border: 1px solid black;
`;

export const ColorBoxGradient = styled.div<any>`
  width: 35px;
  min-width: 35px;
  height: 35px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid black;
  background-image: ${(props) =>
    `linear-gradient(to right, #${props.color.color1} 45%, #${props.color.color2})`};
`;

export const ExpandContentContainer = styled.div`
  margin-top: 20px;
  z-index: 1000;
  height: 80%;
  padding: 10px;
`;

export const GradientExplainer = styled.p`
  margin-bottom: 10px;
`;

export const ColorGroupFlex = styled.div`
  margin-top: 10px;
  display: flex;
`;

export const ColorFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ColorTitle = styled.p`
  margin-bottom: 10px;
`;

export const Divider = styled.div`
  width: 20px;
`;
