import React from "react";
import { ProfilePreviewProps } from "./types";
import {
  PreviewContainer,
  PreviewTitle,
  Preview,
  TopContainer,
  Occupation,
  Name,
  Bio,
  BumpCount,
  BumpCountText,
  BottomContainer,
  Button,
  ButtonText,
  ButtonContainer,
  Divider,
  PictureCircle,
  Picture,
} from "./styles";
import { useProfileState } from "../../context/ProfileContext";
import LinkPreview from "./LinkPreview";

const ProfilePreview: React.FC<Omit<
  ProfilePreviewProps,
  "onSave" | "themeName" | "onThemeNameChange"
>> = ({
  background,
  button,
  buttonText,
  link,
  infoText,
  linkText,
  bumpCountText,
  bumpCount,
}) => {
  const profileState = useProfileState();

  const name = profileState?.name || "First Last";
  const bio = profileState?.bio || "Your bio goes here";

  return (
    <PreviewContainer>
      <PreviewTitle>Preview</PreviewTitle>
      <Preview background={background}>
        <TopContainer>
          <PictureCircle bumpCount={bumpCount}>
            <Picture src={profileState?.picture} />
          </PictureCircle>
          <Name infoText={infoText}>{name}</Name>
          <Occupation infoText={infoText}>Occupation</Occupation>
          <Bio infoText={infoText}>{bio}</Bio>
          <BumpCount bumpCount={bumpCount}>
            <BumpCountText bumpCountText={bumpCountText}>
              <span role="img" aria-label="fire">
                🔥
              </span>{" "}
              1,000 bumps
            </BumpCountText>
          </BumpCount>
          <ButtonContainer>
            <Button button={button}>
              <ButtonText buttonText={buttonText}>Edit Profile</ButtonText>
            </Button>
            <Divider />
            <Button button={button}>
              <ButtonText buttonText={buttonText}>Edit Links</ButtonText>
            </Button>
          </ButtonContainer>
        </TopContainer>
        <BottomContainer>
          <LinkPreview
            type="instagram"
            link={link}
            linkText={linkText}
            title="Instagram"
          />
          <LinkPreview
            type="tiktok"
            link={link}
            linkText={linkText}
            title="TikTok"
          />
          <LinkPreview
            type="twitter"
            link={link}
            linkText={linkText}
            title="Twitter"
          />
        </BottomContainer>
      </Preview>
    </PreviewContainer>
  );
};

export default ProfilePreview;
