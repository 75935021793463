import styled from "styled-components";

export const Page = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
`;

export const PageContainer = styled.div`
  padding: 30px;
  max-width: 700px;
  margin: auto;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`;

export const SubFlex = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 40px;
`;

export const PageTitle = styled.h1`
  font-family: averta-regular;
  color: white;
  font-size: 2rem;
`;

export const SubTitle = styled.h3`
  font-family: averta-regular;
  color: white;
  font-size: 1rem;
`;

export const TitleContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  border: 3px solid ${(props) => props.color};
`;
