import React from "react";
import {
  Page,
  Flex,
  PageTitle,
  NewThemeButton,
  PageContainer,
  ThemeTypeTitle,
} from "./styles";
import ThemeCard from "../../components/ThemeCard";
import { ThemeListUIProps } from "./types";
import { Redirect } from "react-router-dom";
import DefaultThemeCard from "../../components/DefaultThemeCard";
import defaultThemes from "../../theme/defaultThemes";

const ThemeListUI: React.FC<ThemeListUIProps> = ({
  themes,
  activeThemeId,
  onSelect,
  onDefaultSelect,
  usingDefaultTheme,
  selectedDefaultTheme,
}) => {
  const [state, setState] = React.useState("");
  const themeList = themes.map((theme) => {
    return (
      <ThemeCard
        theme={theme}
        active={activeThemeId === theme.id && !usingDefaultTheme}
        onClick={onSelect}
        key={theme.id}
      />
    );
  });

  const defaultThemeList = Object.keys(defaultThemes).map((key) => {
    return (
      <DefaultThemeCard
        theme={defaultThemes[key as any]}
        active={
          usingDefaultTheme &&
          selectedDefaultTheme.toLowerCase() === key.toLowerCase()
        }
        onClick={onDefaultSelect}
        key={key}
      />
    );
  });

  if (state !== "") return <Redirect to={state} />;
  else
    return (
      <Page>
        <PageContainer>
          <Flex>
            <PageTitle>Your themes</PageTitle>
            <NewThemeButton onClick={() => setState("/account/themes/new")}>
              New theme
            </NewThemeButton>
          </Flex>
          <ThemeTypeTitle>Custom Themes</ThemeTypeTitle>
          {themeList}
          <ThemeTypeTitle>Default Themes</ThemeTypeTitle>
          {defaultThemeList}
        </PageContainer>
      </Page>
    );
};

export default ThemeListUI;
