import React, { useState } from "react";
import { HamburgerProps } from "./types";
import styled from "styled-components";
import { LineProps } from "./types";

export const ButtonContainer = styled.button<
  React.ComponentPropsWithoutRef<"button">
>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  cursor: pointer;
`;

export const Line = styled.div<LineProps>`
  height: 3px;
  left: 300px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 3px;
  width: ${(props) => props.width}px;
`;

const Hamburger = React.forwardRef<HTMLButtonElement, HamburgerProps>(
  ({ className, hoverColor = "primary", ...rest }, ref) => {
    const [isHovered, setHovered] = useState<boolean>(false);

    return (
      <ButtonContainer
        ref={ref}
        className={className}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        {...rest}
      >
        <Line width={18} hovered={isHovered} hoverColor={hoverColor} />
        <Line width={12} hovered={isHovered} hoverColor={hoverColor} />
        <Line width={16} hovered={isHovered} hoverColor={hoverColor} />
      </ButtonContainer>
    );
  }
);

export default Hamburger;
