import React from "react";
import {
  Page,
  PageContainer,
  CardContainer,
  SaveButton,
  ButtonContainer,
  ErrorText,
  BackButtonContainer,
} from "./styles";
import WhiteTextField from "../../components/WhiteTextField";
import ColorCard from "../../components/ColorCard";
import { NewThemeUIProps } from "./types";
import ExpandableColorCard from "../../components/ExpandableColorCard";
import ProfilePreview from "../../components/ProfilePreview";
import { BackButton } from "../EditProfile/styles";
import { Redirect } from "react-router-dom";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const NewThemeUI: React.FC<NewThemeUIProps> = ({
  background,
  buttonText,
  button,
  linkText,
  link,
  infoText,
  bumpCount,
  bumpCountText,
  onTextColorChange,
  onColorChange,
  onSave,
  loading,
  themeName,
  onThemeNameChange,
}) => {
  const [state, setState] = React.useState("");
  const [activeCard, setActiveCard] = React.useState("");
  const [nameError, setNameError] = React.useState(false);

  const handleActiveCardChange = (id: string) => {
    if (activeCard === id) {
      setActiveCard("");
    } else {
      setActiveCard(id);
    }
  };

  const handleSaveClick = () => {
    setNameError(false);
    if (themeName.length === 0) {
      setNameError(true);
      return;
    }

    onSave();
  };

  if (state !== "") return <Redirect to={state} />;
  else
    return (
      <Page>
        <PageContainer>
          <BackButtonContainer onClick={() => setState("/account/themes")}>
            <BackButton
              iconProps={{ icon: faAngleRight, flip: "horizontal", size: "lg" }}
            />
          </BackButtonContainer>
          <WhiteTextField
            value={themeName}
            onChange={(event) => onThemeNameChange(event.target.value)}
            error={nameError}
            helperText={nameError ? "Invalid name" : ""}
            placeholder="Name"
            label="Theme Name"
          />
          <CardContainer>
            <ExpandableColorCard
              title="Background"
              id="background"
              active={activeCard === "background"}
              onSetActive={handleActiveCardChange}
              color={background}
              onColorChange={onColorChange}
            />
            <ColorCard
              title="Info Text (Name, Bio, Title)"
              id="info-text"
              active={activeCard === "info-text"}
              color={infoText}
              onColorChange={onTextColorChange}
            />
            <ColorCard
              title="Button Text"
              id="button-text"
              active={activeCard === "background"}
              color={buttonText}
              onColorChange={onTextColorChange}
            />
            <ExpandableColorCard
              title="Button Background"
              id="button-background"
              active={activeCard === "button-background"}
              onSetActive={handleActiveCardChange}
              color={button}
              onColorChange={onColorChange}
            />
            <ColorCard
              title="Link Text"
              id="link-text"
              active={activeCard === "link-text"}
              color={linkText}
              onColorChange={onTextColorChange}
            />
            <ExpandableColorCard
              title="Link Background"
              id="link-background"
              active={activeCard === "link-background"}
              color={link}
              onSetActive={handleActiveCardChange}
              onColorChange={onColorChange}
            />
            <ColorCard
              title="Bump Count Text"
              id="bump-count-text"
              active={activeCard === "bump-count-text"}
              color={bumpCountText}
              onColorChange={onTextColorChange}
            />
            <ExpandableColorCard
              title="Bump Count Background"
              id="bump-count-background"
              active={activeCard === "bump-count-background"}
              onSetActive={handleActiveCardChange}
              color={bumpCount}
              onColorChange={onColorChange}
            />
          </CardContainer>
          <ProfilePreview
            background={background}
            button={button}
            buttonText={buttonText}
            link={link}
            infoText={infoText}
            linkText={linkText}
            bumpCount={bumpCount}
            bumpCountText={bumpCountText}
          />
          <ButtonContainer>
            <SaveButton onClick={handleSaveClick} loading={loading}>
              Save
            </SaveButton>
            {nameError ? <ErrorText>Invalid theme name.</ErrorText> : <div />}
          </ButtonContainer>
        </PageContainer>
      </Page>
    );
};

export default NewThemeUI;
