export default class LocalStorageService {
  load(name: string) {
    try {
      const serializedState = localStorage.getItem(name);
      if (serializedState === null) return undefined;
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }

  save(name: string, item: any) {
    try {
      const serializedState = JSON.stringify(item);
      localStorage.setItem(name, serializedState);
    } catch (err) {
      console.log(err);
    }
  }

  clearStorage() {
    localStorage.clear();
  }
}
