import { LinkType } from "./types";

export function getName(linkType: LinkType): string {
  switch (linkType) {
    case "instagram": {
      return "instagram";
    }

    case "twitter": {
      return "Twitter";
    }

    case "tiktok": {
      return "TikTok";
    }

    case "snapchat": {
      return "Snapchat";
    }

    case "facebook": {
      return "Facebook";
    }

    case "linkedin": {
      return "LinkedIn";
    }

    case "address": {
      return "Address";
    }

    case "website": {
      return "Website";
    }

    case "twitch": {
      return "Twitch";
    }

    case "whatsapp": {
      return "WhatsApp";
    }

    case "paypal": {
      return "PayPal";
    }

    case "cashapp": {
      return "Cash App";
    }

    case "venmo": {
      return "Venmo";
    }

    case "applemusic": {
      return "Apple Music";
    }

    case "spotify": {
      return "Spotify";
    }

    case "soundcloud": {
      return "SoundCloud";
    }

    case "youtube": {
      return "YouTube";
    }

    case "email": {
      return "Email";
    }

    case "github": {
      return "GitHub";
    }

    case "text": {
      return "Text";
    }

    case "contact": {
      return "Contact";
    }

    default: {
      return "Custom";
    }
  }
}

export function getLinkInstruction(linkType: LinkType): string {
  switch (linkType) {
    case "instagram": {
      return 'Enter your Instagram username. For example, if your username is @bob type "bob" below.';
    }
    case "twitter": {
      return 'Enter your Twitter username. For example, if your username is @bob type "bob" below.';
    }

    case "tiktok": {
      return 'Enter your TikTok username. For example, if your username is @bob type "bob" below.';
    }

    case "snapchat": {
      return "Enter your Snapchat username.";
    }

    case "facebook": {
      return "Go to facebook.com and open your profile page. Then copy the url link in the browser and paste it below.";
    }

    case "linkedin": {
      return 'Open your LinkedIn profile and scroll down to the "contact" area. Tap on the "Your profile" section and it will ask you to copy your profile Url. Paste that Url below.';
    }

    case "address": {
      return 'Open Apple Maps or Google Maps and search for the address you would like to share. Open it up and tap on "share". Copy the link and paste it below.';
    }

    case "website": {
      return "Copy the URL for the website you want to share and paste it below.";
    }

    case "twitch": {
      return "Enter your Twitch username below.";
    }

    case "whatsapp": {
      return 'Open WhatsApp and tap on "settings", then tap on your profile. There you will see your phone number. Copy that number and paste it below (you must include your country code).';
    }

    case "paypal": {
      return 'Go to paypal.me in your web browser and tap on the "Create Your PayPal.Me Link. Pase your link down below.';
    }

    case "cashapp": {
      return 'Enter your Cash App username. For example, if your username is $bob type "bob" below.';
    }

    case "venmo": {
      return 'Enter your Venmo username. For example, if your username is @bob type "bob" below.';
    }

    case "applemusic": {
      return 'Open the Apple Music app and go to the song, artist, or playlist you want to share. Tap the three dots and then tap "copy". Paste that link below.';
    }

    case "spotify": {
      return 'Open the Spotify app and go to the song, artist, or playlist you want to share. Tap the three dots, tap "share", then tap "copy link". Paste that link below.';
    }

    case "soundcloud": {
      return 'Open the SoundCloud app and go to the song, artist, or playlist you want to share. Tap the three dots and then "copy link". Paste that link below.';
    }

    case "youtube": {
      return 'Open the YouTube app and go to the video, channel, or playlist you want to share. Tap the "share" button and then "copy link". Paste that link below.';
    }

    case "email": {
      return "Enter the email address you want to share below.";
    }

    case "github": {
      return "Go to the github repository or profile you want to share. Copy the URL from the web browser and paste it below.";
    }

    case "text": {
      return "Enter your phone number below. You must include your country code (for U.S. phone numbers, include the +1 country code). Example: +1 (555) 555-5555";
    }

    case "contact": {
      return "Enter your contact information below.";
    }

    default: {
      return "Enter the URL for your link below.";
    }
  }
}

export function getLabel(linkType: LinkType): string {
  switch (linkType) {
    case "instagram": {
      return "Instagram Username";
    }

    case "twitter": {
      return "Twitter Username";
    }

    case "tiktok": {
      return "TikTok Username";
    }

    case "snapchat": {
      return "Snapchat Username";
    }

    case "facebook": {
      return "Facebook URL";
    }

    case "linkedin": {
      return "LinkedIn URL";
    }

    case "address": {
      return "Map URL";
    }

    case "website": {
      return "Wesbite URL";
    }

    case "twitch": {
      return "Twitch Username";
    }

    case "whatsapp": {
      return "WhatsApp Number";
    }

    case "paypal": {
      return "PayPal Link";
    }

    case "cashapp": {
      return "Cash App Username";
    }

    case "venmo": {
      return "Venmo Username";
    }

    case "applemusic": {
      return "Apple Music Link";
    }

    case "spotify": {
      return "Spotify Link";
    }

    case "soundcloud": {
      return "SoundCloud Link";
    }

    case "youtube": {
      return "Youtube Link";
    }

    case "email": {
      return "Email Address";
    }

    case "github": {
      return "Github URL";
    }

    case "text": {
      return "Phone Number";
    }

    case "contact": {
      return "Contact Information";
    }

    default: {
      return "Url";
    }
  }
}

export function getLinkPath(linkType: LinkType, path: string): string {
  switch (linkType) {
    case "instagram": {
      path = path.toLowerCase();
      return `https://www.instagram.com/${path}`;
    }

    case "twitter": {
      path = path.toLowerCase();
      return `https://www.twitter.com/${path}`;
    }

    case "tiktok": {
      path = path.toLowerCase();
      return `https://www.tiktok.com/@${path}`;
    }

    case "snapchat": {
      path = path.toLowerCase();
      return `https://www.snapchat.com/add/${path}`;
    }

    case "facebook": {
      return createPathWithHttp(path);
    }

    case "linkedin": {
      return createPathWithHttp(path);
    }

    case "address": {
      return createPathWithHttp(path);
    }

    case "website": {
      return createPathWithHttp(path);
    }

    case "twitch": {
      return `https://www.twitch.com/${path}`;
    }

    case "whatsapp": {
      return `https://api.whatsapp.com/send?phone=${path}`;
    }

    case "paypal": {
      return createPathWithHttp(path);
    }

    case "cashapp": {
      return `https://cash.app/$${path}`;
    }

    case "venmo": {
      return `https://venmo.com/${path}`;
    }

    case "applemusic": {
      return createPathWithHttp(path);
    }

    case "spotify": {
      return createPathWithHttp(path);
    }

    case "soundcloud": {
      return createPathWithHttp(path);
    }

    case "youtube": {
      return createPathWithHttp(path);
    }

    case "email": {
      return `mailto:${path}`;
    }

    case "github": {
      return createPathWithHttp(path);
    }

    case "text": {
      return `sms:${path}`;
    }

    case "contact": {
      return path;
    }

    default: {
      return path;
    }
  }
}

function createPathWithHttp(path: string) {
  if (!path.match(/^https?:\/\//i)) {
    console.log(path);
    path = "https://" + path;
  }

  console.log(path);
  return path;
}
