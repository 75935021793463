import React from "react";
import {
  Card,
  CardContainer,
  LeftFlex,
  RightFlex,
  Picture,
  PictureCircle,
  UserTitle,
  UserName,
  DateString,
} from "./styles";
import { UserHistoryCardProps } from "./types";

const UserHistoryCard: React.FC<UserHistoryCardProps> = ({
  user,
  date,
  title,
  picture,
  theme,
  defaultInfo,
  onClick,
}) => {
  return (
    <CardContainer onClick={onClick}>
      <Card background={theme.background}>
        <LeftFlex>
          <PictureCircle bumpCountBackground={theme.bumpCountBackground}>
            <Picture src={picture + `?lastmod=${new Date().getMinutes()}`} />
          </PictureCircle>
          <UserName infoText={theme.infoText}>
            {user}
            <UserTitle infoText={theme.infoText}>{" - " + title}</UserTitle>
          </UserName>
        </LeftFlex>
        <RightFlex>
          <DateString infoText={theme.infoText}>{date}</DateString>
        </RightFlex>
      </Card>
    </CardContainer>
  );
};

export default UserHistoryCard;
