import React from "react";
import Main from "./components/routes/Main";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyles from "./components/styles/GlobalStyles";
import Theme from "./components/styles/Theme";
import { UserProvider } from "./context/UserContext";
import { ProfileProvider } from "./context/ProfileContext";
import "./app.css";

function App() {
  return (
    <div className="App">
      <GlobalStyles />
      <UserProvider>
        <ProfileProvider>
          <Theme>
            <Router>
              <Main />
            </Router>
          </Theme>
        </ProfileProvider>
      </UserProvider>
    </div>
  );
}

export default App;
