import styled from "styled-components";
import { ProfilePreviewProps } from "./types";

export const PreviewContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PreviewTitle = styled.div`
  font-family: averta-extrabold;
  color: white;
  text-align: center;
  font-size: 1.4rem;
`;

export const Preview = styled.div<Pick<ProfilePreviewProps, "background">>`
  margin-top: 30px;
  width: 100%;
  max-width: 350px;

  background: ${(props) =>
    `linear-gradient(to bottom, #${props.background.color1} 60%, #${props.background.color2})`};
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

export const PictureCircle = styled.div<Pick<ProfilePreviewProps, "bumpCount">>`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 13px 30px 3px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  border: 3px solid #${(props) => props.bumpCount.color1};
`;

export const Picture = styled.img`
  width: 100%;
`;

export const Name = styled.h2<Pick<ProfilePreviewProps, "infoText">>`
  font-size: 1.2rem;
  color: #${(props) => props.infoText};
  margin-top: 15px;
  font-family: averta-extrabold;
  letter-spacing: 2px;
`;

export const Occupation = styled.p<Pick<ProfilePreviewProps, "infoText">>`
  font-size: 0.8rem;
  color: #${(props) => props.infoText};
  margin-top: 5px;
  font-family: averta-regular;
`;

export const Bio = styled.p<Pick<ProfilePreviewProps, "infoText">>`
  font-size: 0.8rem;
  margin-top: 15px;
  color: #${(props) => props.infoText};
  font-family: averta-regular;
`;

export const BumpCount = styled.div<Pick<ProfilePreviewProps, "bumpCount">>`
  margin-top: 15px;
  padding: 5px 20px;
  border-radius: 40px;
  background: ${(props) =>
    `linear-gradient(to right, #${props.bumpCount.color1}, #${props.bumpCount.color2})`};
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const Divider = styled.div`
  width: 10px;
`;

export const BumpCountText = styled.p<
  Pick<ProfilePreviewProps, "bumpCountText">
>`
  color: #${(props) => props.bumpCountText};
  font-family: averta-regular;
`;

export const BottomContainer = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonText = styled.p<Pick<ProfilePreviewProps, "buttonText">>`
  color: #${(props) => props.buttonText};
  font-family: averta-regular;
`;

export const Button = styled.div<Pick<ProfilePreviewProps, "button">>`
  margin-top: 15px;
  padding: 5px 20px;
  border-radius: 40px;
  background: ${(props) =>
    `linear-gradient(to right, #${props.button.color1}, #${props.button.color2})`};
`;

export const Card = styled.div<Pick<ProfilePreviewProps, "link">>`
  display: flex;
  box-shadow: 0 0.25em 0.375em rgba(50, 50, 93, 0.09),
    0 0.263em 0.488em rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  background: ${(props) =>
    `linear-gradient(to right, #${props.link.color1}, #${props.link.color2})`};
`;

export const IconParent = styled.div`
  margin-left: 0;
  width: 35px;
  margin-right: 15px;
`;

export const IconContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 100% 0 0 0;
`;

export const IconImg = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const LinkTitle = styled.h1<Pick<ProfilePreviewProps, "linkText">>`
  font-size: 0.8rem;
  color: #${(props) => props.linkText};
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
