import styled from "styled-components";
import { motion } from "framer-motion";
import { Openable } from "./types";
import { Theme } from "../../pages/Profile/types";

export const Nav = styled(motion.nav)<Openable>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  pointer-events: ${(props) => (!props.open ? "none" : "all")};
  overflow: hidden;
`;

export const ToggleButton = styled.button`
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  pointer-events: all;
  z-index: 3;
`;

export const Background = styled(motion.div)<Pick<Theme, "buttonBackground">>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #${(props) => props.buttonBackground.color1};
  z-index: 2;
`;

export const IconPlaceholder = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
`;

export const TextPlaceholder = styled.div`
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
`;

export const Ul = styled(motion.ul)`
  margin: 0;
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
  z-index: 3;
`;

export const Li = styled(motion.li)`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
  cursor: pointer;
  z-index: 4;
`;

export const LinkText = styled.a<Pick<Theme, "buttonText">>`
  color: #${(props) => props.buttonText.color1};
  font-family: averta-extrabold;
  font-size: 1.5rem;
`;

export const Divider = styled.div<Pick<Theme, "buttonText">>`
  width: 100%;
  border-bottom: 3px solid #${(props) => props.buttonText.color1};
  margin-bottom: 25px;
`;
