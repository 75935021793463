import React from "react";
import { useParams } from "react-router-dom";
import FetchService from "../../utils/fetchService";
import { useUserState, useUserDispatch } from "../../context/UserContext";
import { useProfileDispatch } from "../../context/ProfileContext";
import ProfileUI from "./ProfileUI";
import { Link, FetchedTheme, DefaultInfo } from "./types";
import { Theme } from "./types";
import createTheme from "./themeManager";
import defaultThemes from "../../theme/defaultThemes";
import FullPageLoad from "../../components/FullPageLoad";
import useQuery from "../../utils/useQuery";
import NotFound from "../../components/NotFound";

const Profile = () => {
  const [name, setName] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [picture, setPicture] = React.useState("");
  const [error, setError] = React.useState(false);
  const [totalBumps, setTotalBumps] = React.useState(0);
  const [title, setTitle] = React.useState("");
  const [owner, setOwner] = React.useState(false);
  const [links, setLinks] = React.useState<Link[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [theme, setTheme] = React.useState<Theme>(defaultThemes.beach);
  const [defaultInfo, setDefaultInfo] = React.useState<DefaultInfo>({
    isDefault: false,
    selectedDefault: "jetBlack",
  });

  const params = useParams() as { profile: string };
  const userState = useUserState();
  const userDispatch = useUserDispatch();
  const profileDispatch = useProfileDispatch();
  const query = useQuery();
  const queryCode = query.get("code");

  React.useEffect(() => {
    async function fetchUserProfile() {
      const fetchService = new FetchService();
      setLoading(true);
      const result = await fetchService.get(
        `/user/${params.profile}`,
        undefined,
        userState?.jid
      );

      if (result.errors) {
        setError(true);
        setLoading(false);
        return;
      }

      if (result.message === "jwt expired") {
        userDispatch({
          type: "logout",
        });
        return;
      }

      if (result.data.owner) {
        userDispatch({
          type: "login",
          payload: { ...result.data.user, jid: result.data.token },
        });
        profileDispatch({
          type: "login",
          payload: result.data.profile,
        });
        setOwner(true);
      } else {
        setOwner(false);
      }

      setTheme(createTheme(result.data.theme as FetchedTheme));
      setDefaultInfo({
        isDefault: result.data.theme.usingDefaultTheme,
        selectedDefault: result.data.theme.selectedDefaultTheme,
      });
      setLoading(false);
      setName(result.data.profile.name);
      setBio(result.data.profile.bio);
      setPicture(result.data.profile.picture);
      setTitle(result.data.profile.title || "");
      setTotalBumps(result.data.profile.totalBumps || 0);

      const fetchedLinks = result.data.links.sort(
        (a: any, b: any) => a.order - b.order
      );
      setLinks(fetchedLinks);

      if (queryCode) {
        await fetchService.put("/profile/add-bump", {
          username: params.profile,
        });

        if (!result.data.owner) {
          await fetchService.post(
            "/history/create",
            { origin: userState?.username, reception: queryCode },
            userState?.jid
          );
        }
      }
    }

    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.profile, userDispatch]);

  const handleLinkChange = (links: Link[]) => {
    setLinks(links);
  };

  const handleLinkSave = async () => {
    const putBodyLinks = links.map((link, index) => {
      return {
        ...link,
        order: index,
      };
    });
    const fetchService = new FetchService();
    await fetchService.put(
      "/link/edit",
      {
        links: putBodyLinks,
      },
      userState?.jid
    );
  };

  return (
    <>
      {loading ? (
        <FullPageLoad />
      ) : error ? (
        <NotFound
          title="Uh oh.. the user you're looking for doesn't exist!"
          topNav
        />
      ) : (
        <ProfileUI
          name={name}
          bio={bio}
          title={title}
          picture={picture}
          totalBumps={totalBumps}
          owner={owner}
          loading={loading}
          links={links}
          onLinkChange={handleLinkChange}
          onLinkSave={handleLinkSave}
          theme={theme || defaultThemes.jetBlack}
          defaultInfo={defaultInfo}
        />
      )}
    </>
  );
};

export default Profile;
