import React from "react";
import styled from "styled-components";
import Logo from "../../assets/images/uBump-logo.png";
import Hamburger from "../Hamburger";
import Drawer from "../Drawer";
import BackDrop from "../BackDrop";
import { useUserState, useUserDispatch } from "../../context/UserContext";
import { useHistory } from "react-router-dom";
import { useProfileDispatch } from "../../context/ProfileContext";

const Flex = styled.div`
  display: flex;
  padding: 15px 15px 0px 15px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid black;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 2;
`;

const UBumpLogoParent = styled.div`
  margin-left: 0;
  width: 100px;
`;

const UBumpLogoContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 39.23% 0 0 0;
`;

const UBumpLogoImg = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
`;

const Empty = styled.div`
  width: 18px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkItem = styled.a`
  color: white;
  font-size: 1rem;
  padding: 20px;
  cursor: pointer;
  font-family: averta-extrabold;
  font-size: 1rem;

  &:hover {
    background-color: white;
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Divider = styled.div`
  border-bottom: 3px solid white;
  width: 100%;
`;

const TopNav: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const history = useHistory();
  const userState = useUserState();
  const userDispatch = useUserDispatch();
  const profileDispatch = useProfileDispatch();

  let links = [
    { name: "Shop", to: "https://shop.ubump.co/" },
    { name: "FAQ", to: "https://shop.ubump.co/apps/help-center" },
  ];

  const userLinks = [
    { name: "My Profile", to: `/${userState?.username}` },
    { name: "Edit Profile", to: "/account/edit-profile" },
    { name: "Bump History", to: "/account/history" },
    { name: "Themes", to: "/account/themes" },
    { name: "Activate uBump", to: "/account/activate" },
    { name: "Sign Out", to: "/account/login" },
  ];

  // Check if the user is signed in.
  if (userState?.username) {
    links = [...userLinks, { name: "divider", to: "divider" }, ...links];
  } else {
    links = [
      { name: "Home", to: "" },
      ...links,
      { name: "Sign In", to: "/account/login" },
    ];
  }

  return (
    <>
      <Flex>
        <Hamburger onClick={() => setDrawerOpen(!drawerOpen)} />
        <UBumpLogoParent>
          <UBumpLogoContainer>
            <UBumpLogoImg src={Logo} onClick={() => history.push("")} />
          </UBumpLogoContainer>
        </UBumpLogoParent>
        <Empty />
      </Flex>
      <Drawer open={drawerOpen} variant="temporary">
        <List>
          {links.map((link) =>
            link.name === "divider" ? (
              <Divider key={link.name} />
            ) : link.name === "Sign Out" ? (
              <LinkItem
                key={link.name}
                onClick={() => {
                  userDispatch({ type: "logout" });
                  profileDispatch({ type: "logout" });
                  history.push("/account/login");
                }}
              >
                {link.name}
              </LinkItem>
            ) : link.name === "FAQ" || link.name === "Shop" ? (
              <LinkItem key={link.name} href={link.to} target="_blank">
                {link.name}
              </LinkItem>
            ) : (
              <LinkItem key={link.name} onClick={() => history.push(link.to)}>
                {link.name}
              </LinkItem>
            )
          )}
        </List>
      </Drawer>
      <BackDrop
        active={drawerOpen}
        zIndex={1000}
        onClick={() => setDrawerOpen(!drawerOpen)}
      />
    </>
  );
};

export default TopNav;
