import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { LinkProps, Theme } from "./types";
import styled from "styled-components";
import selectIcon from "../../utils/iconSelector";
import EditIcon from "../../assets/images/edit-icon.svg";
import { getLinkPath } from "./linkHelper";
import { saveAs } from "file-saver";

const Card = styled.div<any>`
  display: flex;
  box-shadow: 0 0.25em 0.375em rgba(50, 50, 93, 0.09),
    0 0.263em 0.488em rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  margin-bottom: 15px;
  background-image: linear-gradient(
    to right,
    #${(props) => props.linkBackground.color1},
    #${(props) => props.linkBackground.color2}
  );
  z-index: 1;
`;

const IconParent = styled.div`
  margin-left: 0;
  width: 35px;
  margin-right: 15px;
`;

const IconContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 100% 0 0 0;
`;

const IconImg = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const LinkTitle = styled.h1<Pick<Theme, "linkText">>`
  font-size: 0.8rem;
  color: #${(props) => props.linkText.color1};
`;

const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionButton = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

const Link: React.FC<LinkProps> = ({
  title,
  path,
  order,
  type,
  id,
  theme,
  editable,
  onEditClick,
}) => {
  const [imgSrc] = React.useState(selectIcon(type));

  const handleOpenLink = () => {
    if (editable) return;

    if (type === "contact") {
      const blob = new Blob([path], {
        type: "text/vcard;charset=utf-8",
      });
      saveAs(blob, "contact.vcf");
    } else {
      try {
        window.open(getLinkPath(type, path), "_blank");
      } catch (error) {
        console.log("There was an error");
      }
    }
  };

  return (
    <Draggable
      draggableId={id.toString()}
      index={order}
      isDragDisabled={!editable}
    >
      {(provided) => (
        <Card
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          innerRef={provided.innerRef}
          ref={provided.innerRef}
          linkBackground={theme.linkBackground}
          onClick={handleOpenLink}
        >
          <IconParent>
            <IconContainer>
              <IconImg src={imgSrc} />
            </IconContainer>
          </IconParent>
          <CardContent>
            <LinkTitle linkText={theme.linkText}>{title}</LinkTitle>
            <ActionButtonContainer>
              {editable ? (
                <ActionButton
                  src={EditIcon}
                  onClick={() => onEditClick({ title, id, path, type, order })}
                />
              ) : (
                <div />
              )}
            </ActionButtonContainer>
          </CardContent>
        </Card>
      )}
    </Draggable>
  );
};

export default Link;
