import React from "react";
import styled from "styled-components";
import Logo from "../../assets/images/uBump-logo.png";
import Button from "../../components/Button";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
const ParticlesLibrary = require("react-particles-js");
const Particles = ParticlesLibrary.default;

const Page = styled(motion.div)`
  background-color: ${(props) => props.theme.colors.background};
`;

const PageContainer = styled.div`
  max-width: 600px;
  margin: auto;
`;

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
  z-index: -1;
`;

const UBumpLogoParent = styled.div`
  margin-left: 0;
  width: 250px;
`;

const UBumpLogoContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 39.23% 0 0 0;
`;

const UBumpLogoImg = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Subtitle = styled.h2`
  color: white;
  font-size: 1rem;
  font-weight: 400;
`;

const StyledButton = styled(Button)`
  margin-top: 50px;
  width: 300px;
  z-index: 1;
`;

const Text = styled.p`
  color: white;
  margin-top: 30px;
  margin-bottom: -20px;
`;

const Start = () => {
  let history = useHistory();

  return (
    <Page>
      <Particles
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: true,
                value_area: 1500,
              },
            },
          },
        }}
        style={{
          position: "absolute",
          top: 60,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
      />
      <PageContainer>
        <VerticalFlex>
          <UBumpLogoParent>
            <UBumpLogoContainer>
              <UBumpLogoImg src={Logo} />
            </UBumpLogoContainer>
          </UBumpLogoParent>
          <Subtitle>The future of networking.</Subtitle>
          <StyledButton onClick={() => history.push("/account/login")}>
            Login
          </StyledButton>
          <Text>OR</Text>
          <StyledButton onClick={() => history.push("/account/signup")}>
            Sign up
          </StyledButton>
        </VerticalFlex>
      </PageContainer>
    </Page>
  );
};

export default Start;
