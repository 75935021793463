import React from "react";
import { CssTextField } from "./styles";
import {
  TextFieldProps,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&&&&:hover:before": {
          borderBottom: "1px solid white",
        },
      },
      input: {
        color: "white",
      },
    },
  },
});

const WhiteTextField: React.FC<TextFieldProps> = ({ ...all }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssTextField {...all} />
    </MuiThemeProvider>
  );
};

export default WhiteTextField;
