import React from "react";
import { Card, LeftFlex, ColorBox, ColorRow, ThemeTitle } from "./styles";
import { Checkbox } from "@material-ui/core";
import { DefaultThemeCardProps } from "./types";

const DefaultThemeCard: React.FC<DefaultThemeCardProps> = ({
  theme,
  active,
  onClick,
}) => {
  return (
    <Card>
      <LeftFlex>
        <ColorBox>
          <ColorRow color={`#${theme.background.color1}`} />
          <ColorRow color={`#${theme.buttonBackground.color1}`} />
          <ColorRow color={`#${theme.buttonText.color1}`} />
          <ColorRow color={`#${theme.bumpCountBackground.color1}`} />
          <ColorRow color={`#${theme.bumpCountText.color1}`} />
          <ColorRow color={`#${theme.linkBackground.color1}`} />
          <ColorRow color={`#${theme.linkText.color1}`} />
        </ColorBox>
        <ThemeTitle>{theme.name}</ThemeTitle>
      </LeftFlex>
      <Checkbox
        style={{
          color: "#11B8FF",
        }}
        value="cryon"
        checked={active}
        onClick={() => onClick(theme.nameId)}
      />
    </Card>
  );
};

export default DefaultThemeCard;
