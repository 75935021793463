import React from "react";
import { ProfileUIProps, Link } from "./types";
import LinkContainer from "./LinkContainer";
import BottomModal from "../../components/BottomModal";
import LinkChange from "../../components/LinkChange";
import AddLink from "./AddLink";
import ProfileNav from "../../components/ProfileNav";
import { Redirect } from "react-router-dom";
import { particlesParams } from "./ParticleParams";
import Pfp from "../../assets/images/billboard-pfp.png";

import {
  Page,
  UpperContainer,
  Flex,
  PictureCircle,
  Picture,
  NameText,
  BioText,
  BumpBubble,
  BumpText,
  ButtonFlex,
  ButtonWithShadow,
  Divider,
  LowerContainer,
  TitleText,
  HelpText,
} from "./styles";
import { getLabel, getLinkInstruction } from "./linkHelper";

const ProfileUI: React.FC<ProfileUIProps> = ({
  name,
  bio,
  picture,
  totalBumps,
  owner,
  links,
  title,
  onLinkChange,
  onLinkSave,
  theme,
  defaultInfo,
  loading,
}) => {
  const [state, setState] = React.useState("");
  const [isEditing, setEditing] = React.useState(false);
  const [bottomModalOpen, setBottomModalOpen] = React.useState(false);
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [editingLink, setEditingLink] = React.useState<Link>();
  const [editTitle, setEditTitle] = React.useState("");
  const [editPath, setEditPath] = React.useState("");

  let ParticlesLibrary = null;
  let Particles = null;
  let particleName = "";
  let particle2Name = "";

  if (defaultInfo.isDefault) {
    ParticlesLibrary = require("react-particles-js");
    Particles = ParticlesLibrary.default;
    if (defaultInfo.selectedDefault === "jetBlack")
      particleName = "floating-shapes";
    if (defaultInfo.selectedDefault === "constellations")
      particleName = "stars";
    if (defaultInfo.selectedDefault === "snowyDays") particleName = "snow";
    if (defaultInfo.selectedDefault === "deepSea") particleName = "bubbles";
    if (defaultInfo.selectedDefault === "passion") particleName = "passion";
    //if (defaultInfo.selectedDefault === "peach") particleName = "bewwies";
    if (defaultInfo.selectedDefault === "beach") particleName = "bubbles";
    if (defaultInfo.selectedDefault === "bobaTea") particleName = "boba";
  }

  const params = particlesParams.find((param) => param.name === particleName);
  const params2 = particlesParams.find((param) => param.name === particle2Name);

  const handleEditValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "title" | "path"
  ) => {
    if (type === "title") {
      setEditTitle(event.target.value);
    } else if (type === "path") {
      setEditPath(event.target.value);
    }
  };

  const handleEditClick = async () => {
    if (isEditing) {
      await onLinkSave();
    }

    setEditing(!isEditing);
  };

  const handleDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let movedLink: Link | null = null;
    for (let i = 0; i < links.length; i++) {
      if (links[i].id.toString() === draggableId) {
        movedLink = links[i];
      }
    }

    if (movedLink !== null) {
      const newList = Array.from(links);
      newList.splice(source.index, 1);
      newList.splice(destination.index, 0, movedLink);
      onLinkChange(newList);
    }
  };

  const handleCreateComplete = (createdLink: Link) => {
    const newLinks = [...links, createdLink];
    onLinkChange(newLinks);
    setCreateModalOpen(false);
  };

  const handleEditComplete = (editedLink: Link) => {
    const newLinks = links.map((link) => {
      if (link.id === editedLink.id) {
        return editedLink;
      }
      return link;
    });

    onLinkChange(newLinks);
    setBottomModalOpen(false);
  };

  const handleDelete = (id: number) => {
    const newLinks = links.filter((link) => {
      return link.id !== id;
    });

    onLinkChange(newLinks);
    setBottomModalOpen(false);
  };

  const handleEditIconClick = (link: Link) => {
    setBottomModalOpen(true);
    setEditingLink(link);

    setEditTitle(link.title);
    setEditPath(link.path);
  };

  if (state !== "") return <Redirect to={state} />;

  return (
    <Page background={theme.background}>
      {Particles !== null && params ? (
        <Particles
          params={params.params}
          style={params.styles}
          height={params.height || "100%"}
        />
      ) : (
        <div />
      )}

      {Particles !== null && params2 ? (
        <Particles
          params={params2.params}
          style={params2.styles}
          height={params2.height || "100%"}
        />
      ) : (
        <div />
      )}
      <ProfileNav theme={theme} />
      <UpperContainer>
        <Flex>
          <PictureCircle bumpCountBackground={theme.bumpCountBackground}>
            <Picture
              src={
                picture !== null && picture.length > 0
                  ? picture + `?lastmod=${new Date().getMinutes()}`
                  : Pfp
              }
            />
          </PictureCircle>
          <NameText infoText={theme.infoText}>{name}</NameText>
          {title !== null && title !== "" ? (
            <TitleText infoText={theme.infoText}>{title}</TitleText>
          ) : (
            <div />
          )}
          <BioText infoText={theme.infoText}>{bio}</BioText>
          <BumpBubble bumpCountBackground={theme.bumpCountBackground}>
            <BumpText
              bumpCountText={theme.bumpCountText}
            >{`Total bumps: ${totalBumps}`}</BumpText>
          </BumpBubble>
        </Flex>
        {owner ? (
          <>
            <ButtonFlex>
              <ButtonWithShadow
                buttonBackground={theme.buttonBackground}
                buttonText={theme.buttonText}
                invert
                onClick={() => setState("/account/edit-profile")}
              >
                Edit Profile
              </ButtonWithShadow>
              <Divider />
              <ButtonWithShadow
                buttonBackground={theme.buttonBackground}
                buttonText={theme.buttonText}
                invert
                onClick={handleEditClick}
              >
                {isEditing ? "Save" : "Edit links"}
              </ButtonWithShadow>
            </ButtonFlex>
            {isEditing ? (
              <HelpText buttonBackground={theme.buttonBackground}>
                Tap and hold to drag and rearrange your links!
              </HelpText>
            ) : (
              <div />
            )}
          </>
        ) : (
          <div />
        )}
      </UpperContainer>
      <LowerContainer>
        <LinkContainer
          onDragEnd={handleDragEnd}
          links={links}
          editable={isEditing}
          onEditClick={handleEditIconClick}
          theme={theme}
        />
        {owner ? (
          <ButtonFlex>
            <ButtonWithShadow
              buttonBackground={theme.buttonBackground}
              buttonText={theme.buttonText}
              invert={true}
              onClick={() => setCreateModalOpen(true)}
            >
              Add new link
            </ButtonWithShadow>
          </ButtonFlex>
        ) : (
          <div />
        )}
      </LowerContainer>
      <BottomModal
        onBackdropClick={() => setCreateModalOpen(false)}
        title="Add new link"
        subtitle="Test"
        open={createModalOpen}
      >
        <LinkChange
          label={getLabel(editingLink?.type || "custom")}
          id={editingLink?.id || 0}
          type={editingLink?.type || "custom"}
          order={editingLink?.order || 0}
          job="create"
          onComplete={handleCreateComplete}
        />
      </BottomModal>
      <AddLink
        open={createModalOpen}
        onBackDropClick={() => setCreateModalOpen(false)}
        onComplete={handleCreateComplete}
      />
      <BottomModal
        onBackdropClick={() => setBottomModalOpen(false)}
        title="Edit link"
        subtitle={getLinkInstruction(editingLink?.type || "custom")}
        open={bottomModalOpen}
      >
        <LinkChange
          id={editingLink?.id || 0}
          label={getLabel(editingLink?.type || "custom")}
          type={editingLink?.type || "custom"}
          order={editingLink?.order || 0}
          job="edit"
          onComplete={handleEditComplete}
          onDelete={handleDelete}
          onValueChange={handleEditValueChange}
          editTitle={editTitle}
          editPath={editingLink?.type === "contact" ? undefined : editPath}
        />
      </BottomModal>
    </Page>
  );
};

export default ProfileUI;
