import React from "react";
import { ExpandableColorCardProps } from "./types";
import Expandable from "./Expandable";

const ExpandableColorCard: React.FC<ExpandableColorCardProps> = ({
  title,
  id,
  active,
  onSetActive,
  color,
  onColorChange,
}) => {
  return (
    <Expandable
      title={title}
      id={id}
      active={active}
      color={color}
      onSetActive={onSetActive}
      onColorChange={onColorChange}
    />
  );
};

export default ExpandableColorCard;
