import React from "react";
import UserHistoryListUI from "./UserHistoryListUI";
import FetchService from "../../utils/fetchService";
import { useUserState } from "../../context/UserContext";
import { UserHistoryProfile, UserHistoryParam } from "../../types/history";

const UserHistoryList: React.FC = () => {
  // const [error, setError] = React.useState(false);
  const [originList, setOriginList] = React.useState<
    UserHistoryProfile[] | null
  >(null);
  const [receptionList, setReceptionList] = React.useState<
    UserHistoryProfile[] | null
  >(null);
  const userState = useUserState();

  React.useEffect(() => {
    // setError(false);
    async function fetch() {
      const fetchService = new FetchService();
      const result = await fetchService.get(
        "/history/list",
        undefined,
        userState?.jid
      );
      if (result.data) {
        let olist = [] as UserHistoryProfile[];
        let rlist = [] as UserHistoryProfile[];

        result.data.foundOriginHistory.forEach((entry: UserHistoryParam) => {
          const temp = {
            profileId: entry.receptionUser,
            date: entry.created_at,
          } as UserHistoryProfile;
          olist.push(temp);
        });
        result.data.foundReceptionHistory.forEach((entry: UserHistoryParam) => {
          const temp = {
            profileId: entry.originUser,
            date: entry.created_at,
          } as UserHistoryProfile;
          rlist.push(temp);
        });

        setOriginList(olist);
        setReceptionList(rlist);
      } else {
        // setError(true);
      }
    }

    fetch();
  }, [userState]);

  return (
    <UserHistoryListUI
      originList={originList || []}
      receptionList={receptionList || []}
    />
  );
};

export default UserHistoryList;
