import { FetchedTheme, Theme } from "./types";
import defaultThemes from "../../theme/defaultThemes";

export default function createTheme(fetchedTheme: FetchedTheme): Theme {
  let theme: Theme | null = null;

  if (fetchedTheme.usingDefaultTheme) {
    let selectedDefault =
      defaultThemes[fetchedTheme.selectedDefaultTheme as any];

    if (selectedDefault === null || selectedDefault === undefined) {
      selectedDefault = defaultThemes["jetBlack"];
    }
    theme = {
      background: selectedDefault.background,
      buttonBackground: selectedDefault.buttonBackground,
      buttonText: selectedDefault.buttonText,
      infoText: selectedDefault.infoText,
      bumpCountBackground: selectedDefault.bumpCountBackground,
      bumpCountText: selectedDefault.bumpCountText,
      linkBackground: selectedDefault.linkBackground,
      linkText: selectedDefault.linkText,
    };
  } else {
    const activeTheme = fetchedTheme.activeTheme;
    theme = {
      background: activeTheme.background,
      buttonBackground: activeTheme.buttonBackground,
      buttonText: activeTheme.buttonText,
      infoText: activeTheme.infoText,
      bumpCountBackground: activeTheme.bumpCountBackground,
      bumpCountText: activeTheme.bumpCountText,
      linkBackground: activeTheme.linkBackground,
      linkText: activeTheme.linkText,
    };
  }

  return theme;
}
