import React from "react";
import AvatarEditor from "react-avatar-editor";
import {
  Container,
  SaveButton,
  TopSection,
  BottomSection,
  PrettoSlider,
  Divider,
  ErrorText,
  CloseButon,
} from "./styles";
import FetchService from "../../utils/fetchService";
import { useUserState } from "../../context/UserContext";
import { ProfilePictureEditorProps } from "./types";
import DeleteIcon from "../../assets/images/delete-icon.svg";
import { useProfileDispatch } from "../../context/ProfileContext";

const ProfilePictureEditor: React.FC<ProfilePictureEditorProps> = ({
  onClose,
}) => {
  const [scaleValue, setScaleValue] = React.useState(1);
  const [file, setFile] = React.useState<any>(null);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const editor = React.useRef<any>(null);
  const uploadInput = React.useRef<any>(null);
  const userState = useUserState();
  const profileDispatch = useProfileDispatch();

  const handleUpload = (ev: any) => {
    let file = uploadInput.current.files[0];
    setFile(file);
  };

  const onClickSave = async () => {
    if (editor && file !== null) {
      setLoading(true);
      setErrorMsg("");

      const fetchService = new FetchService();
      const response = await fetchService.put(
        "/profile/edit-picture",
        {
          base64: editor.current.getImage().toDataURL(),
        },
        userState?.jid
      );

      if (response.error) {
        setErrorMsg(
          "There was an error uploading your profile picture. Please try again later."
        );
        return;
      }

      profileDispatch({ type: "picture", payload: response.data.picture });
      setLoading(false);
      onClose();
    } else {
      setErrorMsg("Please select an image.");
    }
  };

  return (
    <Container>
      <TopSection>
        <CloseButon src={DeleteIcon} onClick={onClose} />
      </TopSection>
      <BottomSection>
        <AvatarEditor
          ref={editor}
          image={file}
          width={250}
          height={250}
          border={10}
          borderRadius={10000}
          color={[0, 0, 0, 0.5]} // RGBA
          scale={scaleValue}
          rotate={0}
        />
        <PrettoSlider
          value={scaleValue}
          onChange={(event: any, newValue: any) =>
            setScaleValue(newValue as number)
          }
          min={1}
          max={5}
          defaultValue={1}
          step={0.001}
          aria-labelledby="continuous-slider"
        />
        <Divider />
        <input onChange={handleUpload} ref={uploadInput} type="file" />
        <SaveButton onClick={onClickSave} loading={loading}>
          Save
        </SaveButton>
        {errorMsg.length > 0 ? <ErrorText>{errorMsg}</ErrorText> : <div />}
      </BottomSection>
    </Container>
  );
};

export default ProfilePictureEditor;
