import { withStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";

export const CssTextField = withStyles({
  root: {
    color: "white",
    "&.MuiInputBase-input": {
      color: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline": {
      borderBottomColor: "white",
    },
    "& label": {
      color: "white",
    },
    "& label.Mui-focused": {
      color: "#11B8FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#11B8FF",
    },
    "&:hover": {
      borderBottomColor: "#11B8FF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "#11B8FF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#11B8FF",
      },
    },
  },
})(TextField);
