import React, { createContext } from "react";
import LocalStorageService from "../utils/localStorage";
import { User } from "./types";

type Dispatch = (action: Action) => void;

type Action =
  | { type: "login"; payload: State }
  | { type: "logout" }
  | { type: "username"; payload: string }
  | { type: "email"; payload: string }
  | { type: "password"; payload: string };

interface State extends User {}

type CountProviderProps = { children: React.ReactNode };
const UserStateContext = createContext<State | null>(null);
const UserDispatchContext = createContext<Dispatch>(() => {});

const localStorageInstance = new LocalStorageService();

const userReducer = (state: State | null, action: Action) => {
  switch (action.type) {
    case "login": {
      localStorageInstance.save("user", action.payload);
      return action.payload;
    }

    case "username": {
      if (state) {
        const newUser = {
          ...state,
          username: action.payload,
        };

        localStorageInstance.save("user", newUser);

        return newUser;
      } else {
        return null;
      }
    }

    case "email": {
      if (state) {
        const newUser = {
          ...state,
          email: action.payload,
        };

        localStorageInstance.save("user", newUser);

        return newUser;
      } else {
        return null;
      }
    }

    case "password": {
      if (state) {
        const newUser = {
          ...state,
          password: action.payload,
        };

        localStorageInstance.save("user", newUser);

        return newUser;
      } else {
        return null;
      }
    }

    case "logout": {
      localStorageInstance.clearStorage();
      return null;
    }

    default: {
      throw new Error("Unhandled action type");
    }
  }
};

const UserProvider = ({ children }: CountProviderProps) => {
  const defaultUserData = localStorageInstance.load("user") || null;
  const [state, dispatch] = React.useReducer(userReducer, defaultUserData);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

function useUserState() {
  const context = React.useContext(UserStateContext);
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  return context;
}

export { UserProvider, useUserState, useUserDispatch };
