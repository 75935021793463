import styled from "styled-components";
import Button from "../../components/Button";

export const Page = styled.div`
  background-color: ${(props) => props.theme.colors.background};
`;

export const PageContainer = styled.div`
  padding: 30px;
  max-width: 700px;
  margin: auto;
`;

export const BackButtonContainer = styled.button`
  display: block;
`;

export const PageTitle = styled.h1`
  font-family: averta-regular;
  color: white;
  font-size: 1.2rem;
`;

export const CardContainer = styled.div`
  margin-top: 35px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SaveButton = styled(Button)`
  max-width: 350px;
  margin: auto;
  margin-top: 25px;
`;

export const ErrorText = styled.p`
  margin-top: 7px;
  font-size: 0.8rem;
  color: red;
`;
