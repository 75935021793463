import styled from "styled-components";
import { ColorRowProps } from "./types";

export const Card = styled.div<any>`
  display: flex;
  box-shadow: 0 0.25em 0.375em rgba(50, 50, 93, 0.09),
    0 0.263em 0.488em rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

export const LeftFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ColorBox = styled.div`
  width: 35px;
  min-width: 35px;
  display: flex;
  flex-direction: column;
`;

export const ColorRow = styled.div<ColorRowProps>`
  width: 100%;
  height: 5px;
  background: ${(props) => props.color};
`;

export const ThemeTitle = styled.h1`
  font-family: averta-regular;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.background};
  margin-left: 15px;
`;
