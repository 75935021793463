import styled from "styled-components";
import { motion } from "framer-motion";

export const Page = styled.div`
  min-height: 100vh;
  background: ${(props) => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingText = styled(motion.h1)`
  color: white;
  font-family: averta-extrabold;
  font-size: 1.5rem;
`;
