import React from "react";
import {
  Page,
  TitleContainer,
  SubFlex,
  PageTitle,
  SubTitle,
  PageContainer,
} from "./styles";
import { UserHistoryUIProps } from "./types";
import { HistoryList } from "./HistoryList";
import { motion } from "framer-motion";

const UserHistoryListUI: React.FC<UserHistoryUIProps> = ({
  originList,
  receptionList,
}) => {
  const [viewOrigin, setViewOrigin] = React.useState(true);
  const onToggle = () => {
    setViewOrigin(!viewOrigin);
  };

  return (
    <Page>
      <PageContainer>
        <SubFlex>
          <TitleContainer
            onClick={onToggle}
            color={viewOrigin ? "white" : "hidden"}
          >
            <SubTitle>My Bumps</SubTitle>
          </TitleContainer>
          <PageTitle> | </PageTitle>
          <TitleContainer
            onClick={onToggle}
            color={viewOrigin ? "hidden" : "white"}
          >
            <SubTitle>Bumped Me</SubTitle>
          </TitleContainer>
        </SubFlex>
        <motion.nav>
          <HistoryList userList={viewOrigin ? originList : receptionList} />
        </motion.nav>
      </PageContainer>
    </Page>
  );
};

export default UserHistoryListUI;
