import styled from "styled-components";
import { TextField, withStyles } from "@material-ui/core";
import Icon from "../../components/Icon";

export const Page = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.background};
`;

export const Title = styled.h1`
  font-size: 1.4rem;
  font-weight: 600;
  color: white;
`;

export const SubTitle = styled.h3`
  margin-top: 8px;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.gray};
  font-weight: 400;
  margin-bottom: 30px;
`;

export const ButtonFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  justify-content: flex-end;
`;

export const ArrowButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 100%;
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

export const BackButton = styled(Icon)`
  cursor: pointer;
  margin-bottom: 20px;
  color: white;
`;

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#4A67B1",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4A67B1",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "#4A67B1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4A67B1",
      },
    },
  },
})(TextField);
