import * as React from "react";
import { motion } from "framer-motion";
import { HistoryItem } from "./HistoryItem";
import { Profile } from "../../types/history";
import { HistoryListProps } from "./types";
import { FetchedTheme } from "../Profile/types";

export const HistoryList: React.FC<HistoryListProps> = ({ userList }) => (
  <motion.ul>
    {userList.map((i) => {
      let profile = {
        username: i.profileId.username,
        name: i.profileId.profileId.name,
        title: i.profileId.profileId.title,
        picture: i.profileId.profileId.picture,
        theme: {
          usingDefaultTheme: i.profileId.usingDefaultTheme,
          selectedDefaultTheme: i.profileId.selectedDefaultTheme,
          activeTheme: i.profileId.activeTheme,
        } as FetchedTheme,
      } as Profile;
      return <HistoryItem user={profile} date={i.date} />;
    })}
  </motion.ul>
);
