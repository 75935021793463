import * as React from "react";
import { Li, LinkText, Divider } from "./styles";
import { MenuItemProps } from "./types";
import { useHistory } from "react-router-dom";
import { useProfileDispatch } from "../../context/ProfileContext";
import { useUserDispatch } from "../../context/UserContext";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const MenuItem: React.FC<MenuItemProps> = ({ theme, link }) => {
  const history = useHistory();
  const userDispatch = useUserDispatch();
  const profileDispatch = useProfileDispatch();

  return (
    <Li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      {link.name === "divider" ? (
        <Divider buttonText={theme.buttonText} />
      ) : link.name === "Sign Out" ? (
        <LinkText
          buttonText={theme.buttonText}
          onClick={() => {
            userDispatch({ type: "logout" });
            profileDispatch({ type: "logout" });
            history.push("/account/login");
          }}
        >
          {link.name}
        </LinkText>
      ) : link.name === "FAQ" || link.name === "Shop" ? (
        <LinkText href={link.to} target="_blank" buttonText={theme.buttonText}>
          {link.name}
        </LinkText>
      ) : (
        <LinkText
          buttonText={theme.buttonText}
          onClick={() => history.push(link.to)}
        >
          {link.name}
        </LinkText>
      )}
    </Li>
  );
};
