import React from "react";
import styled from "styled-components";
import { DrawerProps, StyledDrawerProps } from "./types";

const StyledDrawer = styled.nav<StyledDrawerProps>`
  height: 100%;
  width: 180px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background-color: ${(props) =>
    props.variant === "permanent" ? "white" : props.theme.colors.primary};
  z-index: 1001;

  ${(props) => {
    if (props.variant === "temporary") {
      if (props.open) {
        return `
        display: block;
        transform: translateX(0);
        transition: transform 0.2s ease-in;
        box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
      `;
      } else {
        return `
          transform: translateX(-100%);
          transition: transform 0.2s ease-in;
        `;
      }
    }
  }}
`;

const Drawer: React.FC<DrawerProps> = ({
  variant,
  open = true,
  children,
  className = "",
}) => {
  return (
    <StyledDrawer variant={variant} className={className} open={open}>
      {children}
    </StyledDrawer>
  );
};

export default Drawer;
