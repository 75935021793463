import React from "react";
import { useParams, Redirect} from "react-router-dom";
import FetchService from "../../utils/fetchService";
import { useUserState } from "../../context/UserContext";
import ResetPassword from "./ResetPassword"
const Reset = () => {
  const params = useParams() as { code: string };
  const [error, setError] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const userState = useUserState();

  React.useEffect(() => {
    const fetchService = new FetchService();
    async function getCodeInfo() {
      const result = await fetchService.put(
          `/auth/${params.code || ""}`,
          {

          },
          userState?.jid);
      if (result.errors) {
        setError(true);
      }
      else {
        setUsername(result.data.reset.username);
      }
    }

    getCodeInfo();
  }, [params.code]);

  if (error) return <Redirect to="/" />;

  return <ResetPassword username={username}/>;
};

export default Reset;
