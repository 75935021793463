import React from "react";
import { Route, Switch, withRouter, useLocation } from "react-router-dom";
import Start from "../../pages/Start";
import Signup from "../../pages/Signup";
import EditProfile from "../../pages/EditProfile/EditProfile";
import TopNav from "../TopNav";
import Name from "../../pages/EditProfile/Name";
import Username from "../../pages/EditProfile/Username";
import Bio from "../../pages/EditProfile/Bio";
import Email from "../../pages/EditProfile/Email";
import Password from "../../pages/EditProfile/Password";
import Title from "../../pages/EditProfile/Title";
import Login from "../../pages/Login";
import ForgotPassword from "../../pages/ForgotPassword";
import Profile from "../../pages/Profile";
import ThemeList from "../../pages/ThemeList";
import NewTheme from "../../pages/NewTheme";
import { AnimatePresence } from "framer-motion";
import PrivateRoute from "./PrivateRoute";
import { useUserState } from "../../context/UserContext";
import Reroute from "../../pages/Reroute/Reroute";
import Reset from "../../pages/Reset/Reset";
import Activate from "../../pages/Activate";
import UserHistoryList from "../../pages/UserHistory";
import NotFound from "../NotFound";

const Main = withRouter(() => {
  const location = useLocation();
  const userState = useUserState();

  return (
    <>
      {location.pathname.includes("/account") || location.pathname === "/" ? (
        <TopNav />
      ) : (
        <div />
      )}
      <AnimatePresence>
        <Switch>
          <PrivateRoute
            path="/"
            authenticationPath={`/${userState?.username}`}
            isAuthenticated={userState === null}
            exact
            component={Start}
          />
          <Route path="/x/:code" exact component={Reroute} />
          <PrivateRoute
            path="/account/signup"
            authenticationPath={`/${userState?.username}`}
            isAuthenticated={userState === null}
            exact
            component={Signup}
          />
          <PrivateRoute
            path="/account/login"
            authenticationPath={`/${userState?.username}`}
            isAuthenticated={userState === null}
            exact
            component={Login}
          />
          <PrivateRoute
            path="/account/forgot-password"
            authenticationPath={`/${userState?.username}`}
            isAuthenticated={userState === null}
            exact
            component={ForgotPassword}
          />
          <PrivateRoute
            path="/account/edit-profile"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={EditProfile}
          />
          <PrivateRoute
            path="/account/edit-profile/name"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={Name}
          />
          <PrivateRoute
            path="/account/edit-profile/title"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={Title}
          />
          <PrivateRoute
            path="/account/edit-profile/username"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={Username}
          />
          <PrivateRoute
            path="/account/edit-profile/bio"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={Bio}
          />
          <PrivateRoute
            path="/account/edit-profile/email"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={Email}
          />
          <PrivateRoute
            path="/account/edit-profile/password"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={Password}
          />
          <PrivateRoute
            path="/account/history"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={UserHistoryList}
          />
          <PrivateRoute
            path="/account/themes"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={ThemeList}
          />
          <PrivateRoute
            path="/account/themes/new"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={NewTheme}
          />
          <PrivateRoute
            path="/account/activate"
            authenticationPath="/account/login"
            isAuthenticated={userState !== null}
            exact
            component={Activate}
          />
          <Route path="/account/password-reset/:code" exact component={Reset} />
          <Route path="/:profile" exact component={Profile} />
          <NotFound title="This page doesn't exist!" />
        </Switch>
      </AnimatePresence>
    </>
  );
});

export default Main;
