import React from "react";
import { ThemeProvider } from "styled-components";
import ITheme from "../../types/styles";

const screenWidth = {
  minSmall: `(min-width: ${430}px)`,
  minMedium: `(min-width: ${730}px)`,
  minLarge: `(min-width: ${1024}px)`,
  maxSmall: `(max-width: ${430}px)`,
  maxMedium: `(max-width: ${730}px)`,
  maxLarge: `(max-width: ${1024}px)`,
};

const lightTheme: ITheme = {
  colors: {
    primary: "#11B8FF",
    secondary: "#BB87BC",
    primaryComplement: "#e34699",
    gray: "#8E8E8E",
    lightGray: "#d1d1d1",
    background: "#1c1c1c",
    backgroundLight: "f7fcff",
    textPrimary: "#000000",
  },
  screenWidth: { ...screenWidth },
};

// const darkTheme: ITheme = {
//   colors: {
//     primary: "#7000ff",
//     secondary: "#BB87BC",
//     gray: "#6B778D",
//     lightGray: "#efefef",
//     background: "#17223B",
//     backgroundLight: "#263859",
//     textPrimary: "#ffffff",
//   },
//   screenWidth: { ...screenWidth },
// };

interface ThemeProps {
  children: React.ReactNode;
}

const Theme: React.FC<ThemeProps> = ({ children }) => (
  <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>
);

export default Theme;
