import styled from "styled-components";

export const Page = styled.div`
  min-height: 100vh;
  background: ${(props) => props.theme.colors.background};
`;

export const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 30px;
`;

export const SuccessContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SuccessHeader = styled.h1`
  color: white;
  font-size: 1.5rem;
  text-align: center;
`;

export const SuccessBody = styled.p`
  color: white;
  text-align: center;
  margin-top: 4px;
`;
