export default {
  background: {
    color1: "FFAFBD",
    color2: "FFC3A0",
    gradient: true,
  },
  link: {
    color1: "ffffff",
    color2: "ffffff",
    gradient: false,
  },
  button: {
    color1: "1ecbe1",
    color2: "dd00ff",
    gradient: true,
  },
  bumpCount: {
    color1: "9F29B2",
    color2: "9F29B2",
    gradient: false,
  },
};
