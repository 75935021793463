import React from "react";
import Icon from "../../components/Icon";
import { faAngleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FormProps, FormStep } from "./types";
import { BackButton, Title, SubTitle, ButtonFlex, ArrowButton } from "./styles";
import Spinner from "../../components/Spinner";
import { motion } from "framer-motion";
import { pageTransition } from "../animations/variants";
import { spring } from "../animations/config";
import WhiteTextField from "../../components/WhiteTextField";

const Username: React.FC<FormProps> = ({
  onFormSubmit,
  onInputChange,
  onBackButton,
  value,
  error,
  helperText,
  loading,
  direction,
}) => {
  return (
    <motion.div
      exit="out"
      animate="in"
      initial="out"
      variants={pageTransition}
      transition={spring}
      custom={direction === "left" ? -1 : 1}
    >
      <button onClick={() => onBackButton(FormStep.USERNAME)}>
        <BackButton
          iconProps={{
            icon: faAngleRight,
            flip: "horizontal",
            size: "lg",
            color: "white",
          }}
        />
      </button>
      <form onSubmit={(event) => onFormSubmit(event, FormStep.USERNAME)}>
        <Title>Create account</Title>
        <SubTitle>
          Enter a username to set up your account. Your username can consist of
          letters and numbers.
        </SubTitle>
        <WhiteTextField
          id="username"
          label="Username"
          value={value}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => onInputChange(event, FormStep.USERNAME)}
          fullWidth
          size="small"
          error={error}
          helperText={error ? helperText : ""}
        />
        <ButtonFlex>
          <ArrowButton>
            {loading ? (
              <Spinner invert={false}>
                <circle
                  className="path"
                  cx="10"
                  cy="10"
                  r="8"
                  fill="none"
                  strokeWidth="2"
                />
              </Spinner>
            ) : (
              <Icon
                iconProps={{ icon: faArrowRight, color: "white", size: "sm" }}
              />
            )}
          </ArrowButton>
        </ButtonFlex>
      </form>
    </motion.div>
  );
};

export default Username;
