import React from "react";
import { Card, Title, Flex, ColorBox } from "./styles";
import { ColorCardProps } from "./types";
import { Popper, ClickAwayListener } from "@material-ui/core";
import { SketchPicker } from "react-color";

const ColorCard: React.FC<ColorCardProps> = ({
  title,
  active,
  id,
  color,
  onColorChange,
}) => {
  const [changing] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (changing) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [changing]);

  return (
    <Card onClick={handleClick}>
      <Flex>
        <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              <SketchPicker
                color={color}
                onChange={(newColor) =>
                  onColorChange(id, newColor.hex.substring(1))
                }
              />
            </ClickAwayListener>
          )}
        </Popper>
        <Title>{title}</Title>
        <ColorBox color={color} />
      </Flex>
    </Card>
  );
};

export default ColorCard;
