// export type FormStep = "email" | "password" | "confirmPassword" | "username";

export enum FormStep {
  REGISTER_CODE = 0,
  EMAIL,
  USERNAME,
  PASSWORD,
  CONFIRM_PASSWORD,
}

export type Direction = "left" | "right";

export interface FormProps {
  value: string;
  error: boolean;
  loading: boolean;
  helperText?: string;
  onInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: FormStep
  ) => void;
  onFormSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    type: FormStep
  ) => void;
  onBackButton: (type: FormStep) => void;
  direction: Direction;
}

export interface PasswordProps extends FormProps {
  confirmValue: string;
  confirmError: boolean;
  submitError: boolean;
}
