import React from "react";
import {
  ColorBox,
  ExpandContentContainer,
  GradientExplainer,
  ColorFlex,
  Divider,
  ColorGroupFlex,
  ColorTitle,
} from "./styles";
import {
  Checkbox,
  FormControlLabel,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import { SketchPicker } from "react-color";
import { ExpandableColorCardProps } from "./types";

const ExpandableBottom: React.FC<Pick<
  ExpandableColorCardProps,
  "id" | "color" | "onColorChange"
>> = ({ id, color, onColorChange }) => {
  const [currentColor, setCurrentColor] = React.useState(1);
  const [changing, setChanging] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any, selectedColor: number) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(true);
    setCurrentColor(selectedColor);
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (changing) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [changing]);

  return (
    <ExpandContentContainer onClick={() => {}}>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <SketchPicker
              color={currentColor === 1 ? color.color1 : color.color2}
              onChange={(newColor) => {
                setChanging(true);
                onColorChange(id, {
                  type: currentColor === 1 ? "color1" : "color2",
                  payload: newColor.hex.substring(1),
                });
              }}
              onChangeComplete={() => setChanging(false)}
            />
          </ClickAwayListener>
        )}
      </Popper>

      <GradientExplainer>
        Add a two color gradient or pick a single color.
      </GradientExplainer>
      <FormControlLabel
        control={
          <Checkbox
            checked={color.gradient}
            name={`gradient-${id}`}
            color="primary"
            onChange={() =>
              onColorChange(id, { type: "gradient", payload: !color.gradient })
            }
          />
        }
        label="Gradient"
      />
      <ColorGroupFlex>
        <ColorFlex>
          <ColorTitle>Color 1</ColorTitle>
          <ColorBox
            color={color.color1}
            onClick={(event: any) => handleClick(event, 1)}
          />
        </ColorFlex>
        {color.gradient ? (
          <>
            <Divider />
            <ColorFlex>
              <ColorTitle>Color 2</ColorTitle>
              <ColorBox
                color={color.color2}
                onClick={(event: any) => handleClick(event, 2)}
              />
            </ColorFlex>
          </>
        ) : (
          <div />
        )}
      </ColorGroupFlex>
    </ExpandContentContainer>
  );
};

export default ExpandableBottom;
