import React from "react";
import { Card } from "./styles";
import { ExpandableColorCardProps } from "./types";
import ExpandableTop from "./ExpandableTop";
import ExpandableBottom from "./ExpandableBottom";

const variants = {
  open: { height: 250 },
  closed: { height: 55 },
};

const Expandable: React.FC<ExpandableColorCardProps> = ({
  active,
  id,
  onSetActive,
  title,
  color,
  onColorChange,
}) => {
  return (
    <Card animate={active ? "open" : "closed"} variants={variants}>
      <ExpandableTop
        active={active}
        onSetActive={onSetActive}
        title={title}
        id={id}
        color={color}
        onColorChange={onColorChange}
      />
      <ExpandableBottom id={id} color={color} onColorChange={onColorChange} />
    </Card>
  );
};

export default Expandable;
