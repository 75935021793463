import React from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Title, SubTitle, ButtonFlex, BackButton, Page } from "../styles";
import styled from "styled-components";
import Button from "../../../components/Button";
import { Redirect } from "react-router-dom";
import FetchService from "../../../utils/fetchService";
import { useProfileDispatch } from "../../../context/ProfileContext";
import { useUserState } from "../../../context/UserContext";
import WhiteTextField from "../../../components/WhiteTextField";

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 30px;
`;

const Bio: React.FC = () => {
  const [state, setState] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const profileDispatch = useProfileDispatch();
  const userState = useUserState();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setBio(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const fetchServiceInstance = new FetchService();

    setLoading(true);
    const response = await fetchServiceInstance.put(
      "/profile/edit",
      {
        bio,
      },
      userState?.jid
    );
    setLoading(false);

    if (response.errors) {
      setError(true);
      setHelperText(
        "There was an error changing your bio. Please try again later."
      );
    } else {
      profileDispatch({ type: "bio", payload: bio });
    }
    setState("/account/edit-profile");
  };
  if (state !== "") return <Redirect to={state} />;
  else
    return (
      <Page>
        <PageContainer>
          <button onClick={() => setState("/account/edit-profile")}>
            <BackButton
              iconProps={{ icon: faAngleRight, flip: "horizontal", size: "lg" }}
            />
          </button>
          <form onSubmit={handleSubmit}>
            <Title>Change your bio</Title>
            <SubTitle>Tell that world about yourself!</SubTitle>
            <WhiteTextField
              id="bio"
              label="Bio"
              type="text"
              fullWidth
              size="small"
              value={bio}
              onChange={handleInputChange}
              helperText={error ? helperText : ""}
              error={error}
            />
            <ButtonFlex>
              <Button loading={loading}>Submit</Button>
            </ButtonFlex>
          </form>
        </PageContainer>
      </Page>
    );
};

export default Bio;
