import React from "react";
import styled from "styled-components";
import Spinner from "../Spinner";
import { ButtonProps } from "./types";

const StyledButton = styled.button<React.ComponentPropsWithoutRef<any>>`
  cursor: pointer;
  width: 100%;
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 50px;

  ${(props) => {
    if (props.bubble) {
      return `
        box-shadow: 0 0.25em 0.375em rgba(50, 50, 93, 0.09),
          0 0.263em 0.488em rgba(0, 0, 0, 0.4);
        padding: 25px 8px
      `;
    }
  }}
  cursor: pointer;
  background-color: ${(props) =>
    props.invert ? "white" : props.theme.colors.primary};
  color: ${(props) => (props.invert ? props.theme.colors.primary : "white")};
  &:hover {
    opacity: 0.8;
  }
`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, invert = false, children, loading, bubble = false, ...rest },
    ref
  ) => {
    return (
      <StyledButton
        className={className}
        ref={ref}
        invert={invert}
        bubble={bubble}
        {...rest}
      >
        {loading ? (
          <Spinner invert={invert}>
            <circle
              className="path"
              cx="10"
              cy="10"
              r="8"
              fill="none"
              strokeWidth="2"
            />
          </Spinner>
        ) : (
          children
        )}
      </StyledButton>
    );
  }
);

export default Button;
