import styled from "styled-components";
import { Theme } from "../../pages/Profile/types";

export const Card = styled.div<Pick<Theme, "background">>`
  display: flex;
  box-shadow: 0 0.25em 0.375em rgba(50, 50, 93, 0.09),
    0 0.263em 0.488em rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(
    to bottom,
    #${(props) => props.background.color1},
    #${(props) => props.background.color2}
  );
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  cursor: pointer;
`;

export const CardContainer = styled.div<any>`
  display: flex;
`;

export const LeftFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RightFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const PictureBox = styled.div`
  width: 35px;
  min-width: 35px;
  display: flex;
  flex-direction: column;
`;

export const PictureCircle = styled.div<Pick<Theme, "bumpCountBackground">>`
  border-radius: 100%;
  width: 45px;
  height: 45px;
  min-width: 45px;
  background-color: white;
  box-shadow: 0px 13px 30px 3px rgba(0, 0, 0, 0.4);
  z-index: 1;
  overflow: hidden;
  border: 3px solid #${(props) => props.bumpCountBackground.color1};
`;

export const Picture = styled.img`
  width: 100%;
  height: 100%;
`;

export const LinkCircle = styled.div`
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background-color: black;
  margin-right: 10px;
  margin-left: 10px;
`;

export const UserName = styled.h1<Pick<Theme, "infoText">>`
  font-family: averta-regular;
  font-size: 1.6rem;
  color: #${(props) => props.infoText.color1};
  margin-left: 30px;
`;

export const UserTitle = styled.h1<Pick<Theme, "infoText">>`
  font-family: averta-regular;
  font-size: 1.2rem;
  color: #${(props) => props.infoText.color1};
`;

export const DateString = styled.section<Pick<Theme, "infoText">>`
  font-family: averta-regular;
  font-size: 1.2rem;
  color: #${(props) => props.infoText.color1};
  margin-left: 30px;
`;
