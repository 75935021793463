import * as React from "react";
import { useRef } from "react";
import { useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import { Nav } from "./styles";
import { ProfileNavProps } from "./types";
import "./test.css";

const ProfileNav: React.FC<ProfileNavProps> = ({ theme }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <Nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      open={isOpen}
    >
      {/* <Background
        animate={isOpen ? "open" : "closed"}
        variants={sidebar}
        buttonBackground={theme.buttonBackground}
      /> */}
      <div
        className={
          !isOpen ? "background-test" : "background-test animated-test"
        }
        style={{
          color: `#${theme.buttonText.color1}`,
          background: `#${theme.buttonBackground.color1}`,
        }}
      />
      <Navigation theme={theme} />
      <MenuToggle theme={theme} toggle={() => toggleOpen()} />
    </Nav>
  );
};

export default ProfileNav;
