import React from "react";
import styled from "styled-components";
import { BottomModalProps, BackgroundProps } from "./types";
import DeleteIcon from "../../assets/images/delete-icon.svg";

const Backdrop = styled.div<BackgroundProps>`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  z-index: 2;

  ${(props) => {
    if (props.open) {
      return `width: 100%;
      height: 100vh;`;
    }
  }}
`;

const FixedContainer = styled.div<BackgroundProps>`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 3;

  ${(props) => {
    if (props.open) {
      return `
        transform: translateY(0);
        transition: transform 0.3s ease-in;
      `;
    } else {
      return `
          transform: translateY(150%);
          transition: transform 0.3s ease-in;
        `;
    }
  }}
`;

const Background = styled.div<BackgroundProps>`
  background-color: white;
  max-width: 700px;
  padding: 30px;
  width: 100%;
  height: 60vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 -0.25em 0.375em rgba(50, 50, 93, 0.09),
    0 -0.263em 0.488em rgba(0, 0, 0, 0.5);
  z-index: 3;
  overflow-y: scroll;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CloseButon = styled.img`
  width: 30px;
  height: 30px;
  justify-content: center;
  cursor: pointer;
`;

const BlankFiller = styled.div`
  width: 25px;
  height: 25px;
`;

const TitleText = styled.h1`
  font-size: 1.4rem;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 1rem;
  margin: auto;
  text-align: center;
`;

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const ChildrenContainer = styled.div`
  padding: 30xp;
`;

const BottomModal: React.FC<BottomModalProps> = ({
  onBackdropClick,
  title,
  subtitle,
  open,
  children,
}) => {
  return (
    <>
      <Backdrop
        onClick={() => {
          onBackdropClick();
        }}
        open={open}
      />
      <FixedContainer open={open}>
        <Background open={open}>
          <Flex>
            <BlankFiller />
            <TitleText>{title}</TitleText>
            <CloseButon src={DeleteIcon} onClick={onBackdropClick} />
          </Flex>
          <ColumnFlex>
            <Subtitle>{subtitle}</Subtitle>
          </ColumnFlex>
          <ChildrenContainer>{children}</ChildrenContainer>
        </Background>
      </FixedContainer>
    </>
  );
};

export default BottomModal;
