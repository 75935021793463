import { GradientState } from "../pages/NewTheme/types";

export default {
  peach: {
    name: "Peach",
    id: 1,
    nameId: "peach",
    background: {
      gradient: true,
      gradientPercent: 50,
      color1: "FFAFBD",
      color2: "FFC3A0",
    },
    buttonBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "9F29B2",
      color2: "9F29B2",
    },
    infoText: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    buttonText: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    linkBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    linkText: {
      gradient: false,
      gradientPercent: 50,
      color1: "000000",
      color2: "000000",
    },
    bumpCountBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "9F29B2",
      color2: "9F29B2",
    },
    bumpCountText: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
  },
  deepSea: {
    name: "Deep Sea",
    id: 2,
    nameId: "deepSea",
    background: {
      gradient: true,
      gradientPercent: 50,
      color1: "243B55",
      color2: "141E30",
    },
    buttonBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    infoText: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    buttonText: {
      gradient: false,
      gradientPercent: 50,
      color1: "243B55",
      color2: "243B55",
    },
    linkBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    linkText: {
      gradient: false,
      gradientPercent: 50,
      color1: "243B55",
      color2: "243B55",
    },
    bumpCountBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    bumpCountText: {
      gradient: false,
      gradientPercent: 50,
      color1: "243B55",
      color2: "243B55",
    },
  },
  beach: {
    name: "Beach",
    id: 3,
    nameId: "beach",
    background: {
      gradient: true,
      gradientPercent: 50,
      color1: "19547B",
      color2: "FFD89B",
    },
    buttonBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    infoText: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    buttonText: {
      gradient: false,
      gradientPercent: 50,
      color1: "19547B",
      color2: "19547B",
    },
    linkBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    linkText: {
      gradient: false,
      gradientPercent: 50,
      color1: "000000",
      color2: "000000",
    },
    bumpCountBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    bumpCountText: {
      gradient: false,
      gradientPercent: 50,
      color1: "19547B",
      color2: "19547B",
    },
  },
  jetBlack: {
    name: "Jet Black",
    id: 4,
    nameId: "jetBlack",
    background: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
    buttonBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    infoText: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    buttonText: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
    linkBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    linkText: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
    bumpCountBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    bumpCountText: {
      gradient: false,
      gradientPercent: 50,
      color1: "11B8FF",
      color2: "11B8FF",
    },
  },
  constellations: {
    name: "Constellations",
    id: 5,
    nameId: "constellations",
    background: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
    buttonBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    infoText: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    buttonText: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
    linkBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    linkText: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
    bumpCountBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    bumpCountText: {
      gradient: false,
      gradientPercent: 50,
      color1: "11B8FF",
      color2: "11B8FF",
    },
  },
  snowyDays: {
    name: "Snowy Days",
    id: 6,
    nameId: "snowyDays",
    background: {
      gradient: true,
      gradientPercent: 50,
      color1: "9cc1ee",
      color2: "d1e6ff",
    },
    buttonBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    infoText: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    buttonText: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
    linkBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    linkText: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
    bumpCountBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    bumpCountText: {
      gradient: false,
      gradientPercent: 50,
      color1: "11B8FF",
      color2: "11B8FF",
    },
  },
  bobaTea: {
    name: "Boba Tea",
    id: 7,
    nameId: "bobaTea",
    background: {
      gradient: true,
      gradientPercent: 50,
      color1: "e2ae8a",
      color2: "edd7c2",
    },
    buttonBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    infoText: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    buttonText: {
      gradient: false,
      gradientPercent: 50,
      color1: "000000",
      color2: "000000",
    },
    linkBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    linkText: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
    bumpCountBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    bumpCountText: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
  },
  passion: {
    name: "Passion",
    id: 8,
    nameId: "passion",
    background: {
      gradient: true,
      gradientPercent: 50,
      color1: "fc00ff",
      color2: "00dbde",
    },
    buttonBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    infoText: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    buttonText: {
      gradient: false,
      gradientPercent: 50,
      color1: "00dbde",
      color2: "00dbde",
    },
    linkBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    linkText: {
      gradient: false,
      gradientPercent: 50,
      color1: "1c1c1c",
      color2: "1c1c1c",
    },
    bumpCountBackground: {
      gradient: false,
      gradientPercent: 50,
      color1: "ffffff",
      color2: "ffffff",
    },
    bumpCountText: {
      gradient: false,
      gradientPercent: 50,
      color1: "00dbde",
      color2: "00dbde",
    },
  },
} as DefaultThemeOptions;

// 19547B

export interface DefaultThemeOptions {
  [name: string]: Theme;
}

export interface Theme {
  name: string;
  id: number;
  nameId: string;
  background: GradientState;
  buttonBackground: GradientState;
  buttonText: GradientState;
  infoText: GradientState;
  linkBackground: GradientState;
  linkText: GradientState;
  bumpCountBackground: GradientState;
  bumpCountText: GradientState;
}
