import React from "react";
import { ExpandableColorCardProps } from "./types";
import { TitleArrowContainer, Title, Flex, ColorBoxGradient } from "./styles";
import { motion } from "framer-motion";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";

const arrowVariants = {
  open: { rotate: 90 },
  closed: { rotate: 0 },
};

const ExpandableTop: React.FC<ExpandableColorCardProps> = ({
  title,
  onSetActive,
  id,
  active,
  color,
}) => {
  return (
    <Flex onClick={() => onSetActive(id)}>
      <TitleArrowContainer>
        <Title>{title}</Title>
        <motion.div
          animate={active ? "open" : "closed"}
          variants={arrowVariants}
        >
          <Icon
            iconProps={{ icon: faAngleRight, color: "black", size: "sm" }}
          />
        </motion.div>
      </TitleArrowContainer>
      <ColorBoxGradient color={color} />
    </Flex>
  );
};

export default ExpandableTop;
