import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProps } from "./types";

const Icon: React.FC<IconProps> = ({ className, iconProps }) => {
  return (
    <div className={className}>
      <FontAwesomeIcon {...iconProps} />
    </div>
  );
};

export default Icon;
