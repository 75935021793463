import React from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Title, SubTitle, ButtonFlex, BackButton, Page } from "../styles";
import styled from "styled-components";
import Button from "../../../components/Button";
import { Redirect } from "react-router-dom";
import FetchService from "../../../utils/fetchService";
import { useUserState, useUserDispatch } from "../../../context/UserContext";
import WhiteTextField from "../../../components/WhiteTextField";

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 30px;
`;

const Divider = styled.div`
  margin: 10px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
`;

const passwordRegex = /(?=.{8,})/;

const Password: React.FC = () => {
  const [state, setState] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [confirmError, setConfirmError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);

  const userState = useUserState();
  const userDispatch = useUserDispatch();

  if (error) {
    if (RegExp(passwordRegex).test(password)) {
      setError(false);
    }
  }

  if (confirmError) {
    if (password === confirmPassword) {
      setConfirmError(false);
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "password" | "confirm"
  ) => {
    if (type === "password") {
      setPassword(event.target.value);
    } else if (type === "confirm") {
      setConfirmPassword(event.target.value);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const passwordValidated = RegExp(passwordRegex).test(password);
    const confirmPasswordValidated = confirmPassword === password;

    if (!passwordValidated) {
      setError(true);
      return;
    }

    if (!confirmPasswordValidated) {
      setConfirmError(true);
      return;
    }

    setError(false);
    setConfirmError(false);
    setSubmitError(false);
    setLoading(true);
    const fetchServiceInstance = new FetchService();
    const response = await fetchServiceInstance.put(
      "/user/edit",
      {
        password,
      },
      userState?.jid
    );
    setLoading(false);

    if (response.errors) {
      setSubmitError(true);
    } else {
      userDispatch({ type: "password", payload: password });
      setState("/account/edit-profile");
    }

    setState("/account/edit-profile");
  };
  if (state !== "") return <Redirect to={state} />;
  else
    return (
      <Page>
        <PageContainer>
          <button onClick={() => setState("/account/edit-profile")}>
            <BackButton
              iconProps={{ icon: faAngleRight, flip: "horizontal", size: "lg" }}
            />
          </button>
          <form onSubmit={handleSubmit}>
            <Title>Change your password</Title>
            <SubTitle>
              Your password must contain at least eight characters, one letter,
              one capital letter, and one symbol.
            </SubTitle>
            <WhiteTextField
              id="password"
              label="Password"
              type="password"
              fullWidth
              size="small"
              onChange={(event) => handleInputChange(event, "password")}
              error={error}
              helperText={
                error
                  ? "Your password must contain at least eight characters, one letter, one capital letter, and one symbol"
                  : ""
              }
            />
            <Divider />
            <WhiteTextField
              id="confirm-password"
              label="Confirm password"
              type="password"
              fullWidth
              size="small"
              onChange={(event) => handleInputChange(event, "confirm")}
              error={confirmError}
              helperText={confirmError ? "Passwords do not match." : ""}
            />
            {submitError ? (
              <ErrorMessage>
                There was an error changing your password. Please try again
                later.
              </ErrorMessage>
            ) : (
              <div />
            )}

            <ButtonFlex>
              <Button loading={loading}>Submit</Button>
            </ButtonFlex>
          </form>
        </PageContainer>
      </Page>
    );
};

export default Password;
