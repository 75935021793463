import Instagram from "../assets/images/instagram-icon.svg";
import TikTok from "../assets/images/tiktok-icon.svg";
import Snapchat from "../assets/images/snapchat-icon.svg";
import Facebook from "../assets/images/facebook-icon.svg";
import Github from "../assets/images/github-icon.svg";
import Linkedin from "../assets/images/linkedin-icon.svg";
import Twitter from "../assets/images/twitter-icon.svg";
import Address from "../assets/images/address-icon.svg";
import Website from "../assets/images/website-icon.svg";
import Twitch from "../assets/images/twitch-icon.svg";
import Whatsapp from "../assets/images/whatsapp-icon.svg";
import Paypal from "../assets/images/paypal-icon.svg";
import Cashapp from "../assets/images/cashapp-icon.svg";
import Venmo from "../assets/images/venmo-icon.svg";
import AppleMusic from "../assets/images/applemusic-icon.svg";
import Spotify from "../assets/images/spotify-icon.svg";
import Soundcloud from "../assets/images/soundcloud-icon.svg";
import Youtube from "../assets/images/youtube-icon.svg";
import Email from "../assets/images/email-icon.svg";
import Text from "../assets/images/text-icon.svg";
import Link from "../assets/images/link.svg";
import Contact from "../assets/images/contact.png";

import { LinkType } from "../pages/Profile/types";

export default function selectIcon(linkType: LinkType) {
  switch (linkType) {
    case "twitter": {
      return Twitter;
    }
    case "instagram": {
      return Instagram;
    }
    case "tiktok": {
      return TikTok;
    }
    case "snapchat": {
      return Snapchat;
    }
    case "facebook": {
      return Facebook;
    }
    case "linkedin": {
      return Linkedin;
    }
    case "address": {
      return Address;
    }
    case "website": {
      return Website;
    }
    case "twitch": {
      return Twitch;
    }
    case "whatsapp": {
      return Whatsapp;
    }
    case "paypal": {
      return Paypal;
    }
    case "cashapp": {
      return Cashapp;
    }
    case "venmo": {
      return Venmo;
    }
    case "applemusic": {
      return AppleMusic;
    }
    case "spotify": {
      return Spotify;
    }
    case "soundcloud": {
      return Soundcloud;
    }
    case "email": {
      return Email;
    }
    case "youtube": {
      return Youtube;
    }
    case "text": {
      return Text;
    }
    case "github": {
      return Github;
    }
    case "custom": {
      return Link;
    }
    case "contact": {
      return Contact;
    }
    default: {
      return Instagram;
    }
  }
}
