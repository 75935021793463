import React from "react";
import styled from "styled-components";
import { ResetPasswordProps} from "./types";
import {
  Title,
  SubTitle,
  ButtonFlex,
} from "../Signup/styles";
import Button from "../../components/Button";
import { motion } from "framer-motion";
import { pageTransition } from "../animations/variants";
import { spring } from "../animations/config";
import WhiteTextField from "../../components/WhiteTextField";
import { Redirect, Link } from "react-router-dom";
import FetchService from "../../utils/fetchService";
import { useUserState, useUserDispatch } from "../../context/UserContext";


const Page = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  width: 100%;
`;

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 50px 40px;
  background-color: ${(props) => props.theme.colors.background};
`;

const Divider = styled.div`
  margin: 10px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
`;

const passwordRegex = /(?=.{8,})/;

const ResetPassword: React.FC<ResetPasswordProps> = ({
    username
  }) => {
    const [state, setState] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [error, setError] = React.useState(false);
    const [confirmError, setConfirmError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [submitError, setSubmitError] = React.useState(false);
    const userState = useUserState();
    const userDispatch = useUserDispatch();

    if (error) {
        if (RegExp(passwordRegex).test(password)) {
          setError(false);
        }
      }
    
    if (confirmError) {
        if (password === confirmPassword) {
          setConfirmError(false);
        }
    }

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: "password" | "confirm"
      ) => {
        if (type === "password") {
          setPassword(event.target.value);
        } else if (type === "confirm") {
          setConfirmPassword(event.target.value);
        }
      };
    
      const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const passwordValidated = RegExp(passwordRegex).test(password);
        const confirmPasswordValidated = confirmPassword === password;
    
        if (!passwordValidated) {
          setError(true);
          return;
        }
    
        if (!confirmPasswordValidated) {
          setConfirmError(true);
          return;
        }
    
        setError(false);
        setConfirmError(false);
        setSubmitError(false);
        setLoading(true);
        const fetchServiceInstance = new FetchService();
        const response = await fetchServiceInstance.put(
          "/auth/reset-password",
          {
            username,
            password,
            confirmPassword
          },
          userState?.jid
        );
        setLoading(false);
        if (response.errors) {
          setSubmitError(true);
        } else {
          setState("reset");
        }
        setState("reset");
      };

    if (state === "reset") return(
        <Page>
            <PageContainer>
                <Title>Password has been reset</Title>
                <SubTitle>
                    <Link to="/account/login" style={{ color: "#11B8FF" }}>
                        Log in {" "}
                    </Link>
                    with your new password.
                </SubTitle>
            </PageContainer>
        </Page>
    );

    return (
      <Page>
        <PageContainer>
          <motion.div
            exit="out"
            animate="in"
            initial="out"
            variants={pageTransition}
            transition={spring}
            custom={1}
          >
            <form onSubmit={handleSubmit}>
            <Title>Reset your password</Title>
            <SubTitle>
              Your password must contain at least eight characters.
            </SubTitle>
            <WhiteTextField
              id="password"
              label="Password"
              type="password"
              fullWidth
              size="small"
              onChange={(event) => handleInputChange(event, "password")}
              error={error}
              helperText={
                error
                  ? "Your password must contain at least eight characters."
                  : ""
              }
            />
            <Divider />
            <WhiteTextField
              id="confirm-password"
              label="Confirm password"
              type="password"
              fullWidth
              size="small"
              onChange={(event) => handleInputChange(event, "confirm")}
              error={confirmError}
              helperText={confirmError ? "Passwords do not match." : ""}
            />
            {submitError ? (
              <ErrorMessage>
                There was an error resetting your password. Please try again
                later.
              </ErrorMessage>
            ) : (
              <div />
            )}
            <ButtonFlex>
              <Button loading={loading}>Submit</Button>
            </ButtonFlex>
          </form>
          </motion.div>
        </PageContainer>
      </Page>
    );
};

export default ResetPassword;
