import * as React from "react";
import { MenuItem } from "./MenuItem";
import { Ul } from "./styles";
import { ProfileNavProps } from "./types";
import { useUserState } from "../../context/UserContext";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation: React.FC<ProfileNavProps> = ({ theme }) => {
  const userState = useUserState();
  let links = [
    { name: "Shop", to: "https://shop.ubump.co/" },
    { name: "FAQ", to: "https://shop.ubump.co/apps/help-center" },
  ];

  const userLinks = [
    { name: "My Profile", to: `/${userState?.username}` },
    { name: "Edit Profile", to: "/account/edit-profile" },
    { name: "Themes", to: "/account/themes" },
    { name: "Bump History", to: "/account/history" },
    { name: "Activate uBump", to: "/account/activate" },
    { name: "Sign Out", to: "/account/login" },
  ];

  // Check if the user is signed in.
  if (userState?.username) {
    links = [...userLinks, { name: "divider", to: "divider" }, ...links];
  } else {
    links = [...links, { name: "Sign In", to: "/account/login" }];
  }

  return (
    <Ul variants={variants}>
      {links.map((link) => (
        <MenuItem link={link} theme={theme} key={link.name} />
      ))}
    </Ul>
  );
};
