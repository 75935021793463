import styled from "styled-components";
import { SpinnerProps } from "./types";

const Spinner = styled.svg<SpinnerProps>`
  animation: rotate 1s linear infinite;
  width: 20px;
  height: 20px;

  & .path {
    stroke: ${(props) => (props.invert ? props.theme.colors.primary : "white")};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default Spinner;
