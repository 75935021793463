import React, { useState, useReducer } from "react";
import NewThemeUI from "./NewThemeUI";
import { GradientAction, GradientState } from "./types";
import initialValues from "./initialValues";
import FetchService from "../../utils/fetchService";
import { useUserState } from "../../context/UserContext";
import { useHistory } from "react-router-dom";

const gradientReducer = (state: GradientState, action: GradientAction) => {
  switch (action.type) {
    case "color1": {
      if (!state.gradient) {
        return {
          ...state,
          color1: action.payload,
          color2: action.payload,
        };
      } else {
        return {
          ...state,
          color1: action.payload,
        };
      }
    }
    case "color2": {
      return {
        ...state,
        color2: action.payload,
      };
    }
    case "gradient": {
      return {
        ...state,
        gradient: action.payload,
        color2: !action.payload ? state.color1 : state.color2,
      };
    }
    default: {
      return state;
    }
  }
};

const NewTheme = () => {
  const userState = useUserState();
  const history = useHistory();

  const [themeName, setThemeName] = useState("");

  const [background, backgroundDispatch] = useReducer(
    gradientReducer,
    initialValues.background
  );

  const [button, buttonDispatch] = useReducer(
    gradientReducer,
    initialValues.button
  );
  const [link, linkDispatch] = useReducer(gradientReducer, initialValues.link);
  const [bumpCount, bumpCountDispatch] = useReducer(
    gradientReducer,
    initialValues.bumpCount
  );

  const [buttonText, setButtonText] = useState("ffffff");
  const [linkText, setLinkText] = useState("000000");
  const [infoText, setInfoText] = useState("ffffff");
  const [bumpCountText, setBumpCountText] = useState("ffffff");

  const [loadingSave, setLoadingSave] = useState(false);

  const handleTextColorChange = (id: string, hex: string) => {
    switch (id) {
      case "button-text": {
        setButtonText(hex);
        break;
      }
      case "link-text": {
        setLinkText(hex);
        break;
      }
      case "bump-count-text": {
        setBumpCountText(hex);
        break;
      }
      case "info-text": {
        setInfoText(hex);
        break;
      }
    }
  };

  const handleColorChange = (id: string, dispatch: GradientAction) => {
    switch (id) {
      case "background": {
        backgroundDispatch(dispatch);
        break;
      }
      case "link-background": {
        linkDispatch(dispatch);
        break;
      }
      case "bump-count-background": {
        bumpCountDispatch(dispatch);
        break;
      }
      case "button-background": {
        buttonDispatch(dispatch);
        break;
      }
    }
  };

  const handleSave = async () => {
    setLoadingSave(true);
    const fetchService = new FetchService();
    const result = await fetchService.post(
      "/theme/create",
      {
        name: themeName,
        background,
        bumpCountBackground: bumpCount,
        bumpCountText: {
          color1: bumpCountText,
          color2: bumpCountText,
          gradient: false,
        },
        buttonBackground: button,
        buttonText: {
          color1: buttonText,
          color2: buttonText,
          gradient: false,
        },
        linkText: {
          color1: linkText,
          color2: linkText,
          gradient: false,
        },
        linkBackground: link,
        infoText: {
          color1: infoText,
          color2: infoText,
          gradient: false,
        },
      },
      userState?.jid
    );

    if (result.data) {
      history.push("/account/themes");
    }
    setLoadingSave(false);
  };

  return (
    <NewThemeUI
      background={background}
      buttonText={buttonText}
      button={button}
      linkText={linkText}
      link={link}
      bumpCountText={bumpCountText}
      bumpCount={bumpCount}
      onTextColorChange={handleTextColorChange}
      onColorChange={handleColorChange}
      onSave={handleSave}
      infoText={infoText}
      loading={loadingSave}
      themeName={themeName}
      onThemeNameChange={(name) => setThemeName(name)}
    />
  );
};

export default NewTheme;
