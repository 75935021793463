import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { TextField, withStyles } from "@material-ui/core";
import Button from "../../components/Button";
import FetchService from "../../utils/fetchService";
import { useProfileDispatch } from "../../context/ProfileContext";
import { useUserDispatch } from "../../context/UserContext";
import { Redirect, Link } from "react-router-dom";
import WhiteTextField from "../../components/WhiteTextField";
import useQuery from "../../utils/useQuery";

const Page = styled.div`
  width: 100%;
  height: 100vh;
  background: ${(props) => props.theme.colors.background};
`;

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 30px;
`;

export const Title = styled.h1`
  font-size: 1.4rem;
  font-weight: 600;
  color: white;
`;

export const SubTitle = styled.h3`
  margin-top: 8px;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.gray};
  font-weight: 400;
  margin-bottom: 30px;
`;

export const ButtonFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  justify-content: flex-end;
`;

export const ArrowButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 100%;
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

const Divider = styled.div`
  margin-top: 15px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
`;

export const SignupText = styled.p`
  color: white;
  position: absolute;
  text-align: center;
  bottom: 80px;
  width: 100%;
`;

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#4A67B1",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4A67B1",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "#4A67B1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4A67B1",
      },
    },
  },
})(TextField);

const Login = () => {
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const userDispatch = useUserDispatch();
  const profileDispatch = useProfileDispatch();
  const query = useQuery();
  const codeQuery = query.get("code");

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "email" | "password"
  ) => {
    if (type === "email") {
      setEmail(event.target.value);
    } else if (type === "password") {
      setPassword(event.target.value);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    const fetchServiceInstance = new FetchService();
    const result = await fetchServiceInstance.post("/auth/login", {
      email,
      password,
    });
    setLoading(false);

    if (result.errors) {
      setError(true);
    } else {
      userDispatch({
        type: "login",
        payload: { ...result.data.user, jid: result.data.token },
      });
      profileDispatch({ type: "login", payload: result.data.profile });
      setState(`/${result.data.user.username}`);
    }
  };

  if (state !== "") return <Redirect to={state} />;
  else
    return (
      <Page>
        <PageContainer>
          <form onSubmit={handleSubmit}>
            <Title>Login</Title>
            <SubTitle>Welcome back :)</SubTitle>
            <WhiteTextField
              id="email"
              label="Email"
              type="email"
              fullWidth
              size="small"
              onChange={(event) => handleInputChange(event, "email")}
            />
            <Divider />
            <WhiteTextField
              id="password"
              label="Password"
              type="password"
              fullWidth
              size="small"
              onChange={(event) => handleInputChange(event, "password")}
            />
            <Link style={{ color: "#11B8FF" }} to={`/account/forgot-password`}>
              Forgot Password?
            </Link>
            {error ? (
              <ErrorMessage>Your email or password is incorrect.</ErrorMessage>
            ) : (
              <div />
            )}
            <ButtonFlex>
              <Button loading={loading}>Login</Button>
            </ButtonFlex>
          </form>
        </PageContainer>
        <SignupText>
          Don't have an account? Sign up{" "}
          <Link
            style={{ color: "#11B8FF" }}
            to={`/account/signup${codeQuery ? "?code=" + codeQuery : ""}`}
          >
            here
          </Link>
          .
        </SignupText>
      </Page>
    );
};

export default Login;
