import React from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Title, SubTitle, ButtonFlex, BackButton, Page } from "../styles";
import styled from "styled-components";
import Button from "../../../components/Button";
import { Redirect } from "react-router-dom";
import FetchService from "../../../utils/fetchService";
import { useProfileDispatch } from "../../../context/ProfileContext";
import { useUserState } from "../../../context/UserContext";
import WhiteTextField from "../../../components/WhiteTextField";

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 30px;
`;

const Name: React.FC = () => {
  const [state, setState] = React.useState("");
  const [name, setName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const profileDispatch = useProfileDispatch();
  const userState = useUserState();

  if (error) {
    if (name.length < 51) {
      setError(false);
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const fetchServiceInstance = new FetchService();

    if (name.length > 50) {
      setError(true);
      setHelperText("Name cannot be more than 50 characters");
      return;
    }

    setLoading(true);
    const response = await fetchServiceInstance.put(
      "/profile/edit",
      {
        name,
      },
      userState?.jid
    );
    setLoading(false);

    if (response.errors) {
      setError(true);
      setHelperText(
        "There was an error changing your name. Please try again later."
      );
    } else {
      profileDispatch({ type: "name", payload: name });
    }
    setState("/account/edit-profile");
  };

  if (state !== "") return <Redirect to={state} />;
  else
    return (
      <Page>
        <PageContainer>
          <button onClick={() => setState("/account/edit-profile")}>
            <BackButton
              iconProps={{ icon: faAngleRight, flip: "horizontal", size: "lg" }}
            />
          </button>
          <form onSubmit={handleSubmit}>
            <Title>Change your name</Title>
            <SubTitle>
              Your name is displayed at the top of your profile.
            </SubTitle>
            <WhiteTextField
              id="name"
              label="Name"
              type="text"
              fullWidth
              size="small"
              value={name}
              onChange={handleInputChange}
              helperText={error ? helperText : ""}
              error={error}
            />
            <ButtonFlex>
              <Button loading={loading}>Submit</Button>
            </ButtonFlex>
          </form>
        </PageContainer>
      </Page>
    );
};

export default Name;
