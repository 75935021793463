import React from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  Title as TextTitle,
  SubTitle,
  ButtonFlex,
  BackButton,
  Page,
} from "../styles";
import styled from "styled-components";
import Button from "../../../components/Button";
import { Redirect } from "react-router-dom";
import FetchService from "../../../utils/fetchService";
import { useProfileDispatch } from "../../../context/ProfileContext";
import { useUserState } from "../../../context/UserContext";
import WhiteTextField from "../../../components/WhiteTextField";

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 30px;
`;

const Title: React.FC = () => {
  const [state, setState] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const profileDispatch = useProfileDispatch();
  const userState = useUserState();

  if (error) {
    if (title.length < 51) {
      setError(false);
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTitle(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const fetchServiceInstance = new FetchService();

    if (title.length > 50) {
      setError(true);
      setHelperText("Title cannot be more than 50 characters");
      return;
    }

    setLoading(true);
    const response = await fetchServiceInstance.put(
      "/profile/edit",
      {
        title,
      },
      userState?.jid
    );
    setLoading(false);

    if (response.errors) {
      setError(true);
      setHelperText(
        "There was an error changing your title. Please try again later."
      );
    } else {
      profileDispatch({ type: "title", payload: title });
    }
    setState("/account/edit-profile");
  };

  if (state !== "") return <Redirect to={state} />;
  else
    return (
      <Page>
        <PageContainer>
          <button onClick={() => setState("/account/edit-profile")}>
            <BackButton
              iconProps={{ icon: faAngleRight, flip: "horizontal", size: "lg" }}
            />
          </button>
          <form onSubmit={handleSubmit}>
            <TextTitle>Change your title</TextTitle>
            <SubTitle>
              Your title should be a short phrase that describes you. For
              example: "Entrepreneur", "Influencer", or "Just for fun".
            </SubTitle>
            <WhiteTextField
              id="title"
              label="Title"
              type="text"
              fullWidth
              size="small"
              value={title}
              onChange={handleInputChange}
              helperText={error ? helperText : ""}
              error={error}
            />
            <ButtonFlex>
              <Button loading={loading}>Submit</Button>
            </ButtonFlex>
          </form>
        </PageContainer>
      </Page>
    );
};

export default Title;
