import smallPearl from "../../assets/images/smallPearl.png";
import mediumPearl from "../../assets/images/mediumPearl.png";
import largePearl from "../../assets/images/largePearl.png";
import peach1 from "../../assets/images/peach.svg";
import peach2 from "../../assets/images/peach2.svg";
import peach3 from "../../assets/images/peach3.svg";

export const particlesParams = [
  {
    name: "floating-shapes",
    params: {
      particles: {
        number: {
          value: 50,
          density: {
            enable: true,
            value_area: 1500,
          },
        },
      },
    },
    styles: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0,
    },
  },
  {
    name: "bewwies",
    params: {
      particles: {
        number: {
          value: 50,
          density: {
            enable: true,
            value_area: 1000,
          },
        },
        move: {
          direction: "bottom",
          out_mode: "out",
          speed: 2,
        },
        line_linked: {
          enable: false,
        },
        size: {
          value: 50,
          random: false,
          anim: {
            speed: 4,
            size_min: 0.3,
          },
        },
        shape: {
          type: ["image"],
          image: [
            {
              src: peach1,
              height: 90,
              width: 90,
            },
            {
              src: peach2,
              height: 70,
              width: 70,
            },
            {
              src: peach3,
              height: 90,
              width: 90,
            },
          ],
        },
      },
    },
    styles: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0,
    },
  },
  {
    name: "bubbles",
    params: {
      particles: {
        number: {
          value: 160,
          density: {
            enable: false,
          },
        },
        size: {
          value: 3,
          random: true,
          anim: {
            speed: 4,
            size_min: 0.3,
          },
        },
        line_linked: {
          enable: false,
        },
      },
      interactivity: {
        modes: {
          bubble: {
            distance: 250,
            duration: 2,
            size: 0,
            opacity: 0,
          },
          repulse: {
            distance: 400,
            duration: 4,
          },
        },
      },
    },
    styles: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0,
    },
  },
  {
    name: "passion",
    params: {
      particles: {
        number: {
          value: window.innerWidth / 15,
          density: {
            enable: false,
          },
        },
        move: {
          speed: 1,
        },
        size: {
          value: 50,
          random: true,
          anim: {
            speed: 4,
            size_min: 0.3,
          },
        },
        opacity: {
          value: 0.4,
          anim: {
            enable: true,
            speed: 0.2,
            opacity_min: 0.1,
          },
        },
        line_linked: {
          enable: false,
        },
      },
      interactivity: {
        modes: {
          bubble: {
            distance: 250,
            duration: 2,
            size: 0,
            opacity: 0,
          },
          repulse: {
            distance: 100,
            duration: 2,
          },
        },
        events: {
          onclick: {
            enable: true,
            mode: "repulse",
          },
        },
      },
    },
    styles: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0,
    },
  },
  {
    name: "snow",
    params: {
      particles: {
        number: {
          value: 500,
          density: {
            enable: false,
          },
        },
        move: {
          direction: "bottom",
          out_mode: "out",
          speed: 5,
        },
        size: {
          value: 3,
          random: true,
        },
        line_linked: {
          enable: false,
        },
      },
      interactivity: {
        modes: {
          bubble: {
            distance: 250,
            duration: 2,
            size: 0,
            opacity: 0,
          },
          repulse: {
            distance: 400,
            duration: 1,
          },
        },
        events: {
          onclick: {
            enable: false,
            mode: "repulse",
          },
        },
      },
    },
    styles: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0,
    },
  },
  {
    name: "boba",
    params: {
      particles: {
        number: {
          value: window.innerWidth / 40,
          density: {
            enable: false,
          },
        },
        color: {
          value: "000000",
        },
        move: {
          speed: 2,
        },
        shape: {
          type: ["image"],
          image: [
            {
              src: smallPearl,
              height: 20,
              width: 20,
            },
            {
              src: mediumPearl,
              height: 70,
              width: 70,
            },
            {
              src: largePearl,
              height: 90,
              width: 90,
            },
          ],
        },
        opacity: {
          value: 0.7,
          random: false,
          anim: {
            enable: false,
            speed: 0.05,
            opacity_min: 0.1,
          },
        },
        size: {
          value: 30,
          random: false,
        },
        line_linked: {
          enable: false,
        },
      },
      interactivity: {
        modes: {
          push: {
            particles_nb: 1,
          },
        },
        events: {
          onclick: {
            enable: true,
            mode: "push",
          },
        },
      },
    },
    styles: {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
    },
    height: "25%",
  },
  {
    name: "stars",
    params: {
      particles: {
        number: {
          value: 60,
          density: {
            enable: false,
            value_area: 1500,
          },
        },
        size: {
          value: 2,
        },
        line_linked: {
          enable: true,
          opacity: 0.1,
        },
        opacity: {
          anim: {
            enable: true,
            speed: 0.25,
            opacity_min: 0.05,
          },
        },
        move: {
          direction: "right",
          speed: 0.1,
          out_mode: "out",
        },
      },
      interactivity: {
        modes: {
          bubble: {
            size: 8,
            distance: 20,
          },
          push: {
            particles_nb: 1,
          },
        },
        events: {
          onhover: {
            enable: true,
            mode: "bubble",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
        },
      },
    },
    styles: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0,
    },
  },
];
