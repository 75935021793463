import React from "react";
import styled from "styled-components";
import { VCardFormTypes } from "./types";
import { TextField, withStyles } from "@material-ui/core";

const Divider = styled.div`
  height: 10px;
`;

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#4A67B1",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4A67B1",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "#4A67B1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4A67B1",
      },
    },
  },
})(TextField);
const VCardForm: React.FC<VCardFormTypes> = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  businessName,
  onFirstNameChange,
  onLastNameChange,
  onEmailChange,
  onPhoneNumberChange,
  onBusinessNameChange,
}) => {
  return (
    <div>
      <CssTextField
        label={"First name"}
        fullWidth
        value={firstName}
        onChange={(event) => onFirstNameChange(event.target.value)}
      />
      <Divider />
      <CssTextField
        label="Last name"
        fullWidth
        value={lastName}
        onChange={(event) => onLastNameChange(event.target.value)}
      />
      <Divider />
      <CssTextField
        label={"Business name"}
        fullWidth
        value={businessName}
        onChange={(event) => onBusinessNameChange(event.target.value)}
      />
      <Divider />
      <CssTextField
        label={"Email"}
        fullWidth
        value={email}
        onChange={(event) => onEmailChange(event.target.value)}
      />
      <Divider />
      <CssTextField
        label="Phone number"
        fullWidth
        value={phoneNumber}
        onChange={(event) => onPhoneNumberChange(event.target.value)}
      />
    </div>
  );
};

export default VCardForm;
