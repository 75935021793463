import * as React from "react";
import { motion } from "framer-motion";
import { HistoryItemProps } from "./types";
import UserHistoryCard from "../../components/UserHistoryCard";
import { useHistory } from "react-router-dom";
import createTheme from "../../pages/Profile/themeManager";
import Pfp from "../../assets/images/billboard-pfp.png";

export const HistoryItem: React.FC<HistoryItemProps> = ({ user, date }) => {
  const history = useHistory();
  const dateString = new Date(date).toLocaleDateString();

  return (
    <motion.div
      transition={{ y: { stiffness: 1000, velocity: -100 } }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <UserHistoryCard
        user={user.name}
        date={dateString}
        title={user.title}
        picture={user.picture !== null && user.picture.length > 0
                 ? user.picture 
                 : Pfp}
        theme={createTheme(user.theme)}
        defaultInfo={
          user.theme.usingDefaultTheme ? user.theme.selectedDefaultTheme : ""
        }
        onClick={() => history.push("/" + user.username)}
      />
    </motion.div>
  );
};
