import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { LinkContainerProps } from "./types";
import Link from "./Link";

const Flex = styled.div<any>`
  display: flex;
  flex-direction: column;
`;

const LinkContainer: React.FC<LinkContainerProps> = ({
  onDragEnd,
  links,
  editable,
  onEditClick,
  theme,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="link-list">
        {(provided) => (
          <Flex
            ref={provided.innerRef}
            innerRef={provided.innerRef}
            {...provided.droppableProps}
          >
            {links.map((link, index) => (
              <Link
                title={link.title}
                path={link.path}
                order={index}
                key={link.id}
                type={link.type}
                id={link.id}
                editable={editable}
                onEditClick={onEditClick}
                theme={theme}
              />
            ))}

            {provided.placeholder}
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default LinkContainer;
