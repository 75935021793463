import React from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Title, SubTitle, ButtonFlex, BackButton, Page } from "../styles";
import styled from "styled-components";
import Button from "../../../components/Button";
import { Redirect } from "react-router-dom";
import FetchService from "../../../utils/fetchService";
import { useUserState, useUserDispatch } from "../../../context/UserContext";
import WhiteTextField from "../../../components/WhiteTextField";

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 30px;
`;
const usernameRegex = /^[a-z0-9_-]{3,15}$/;

const Username: React.FC = () => {
  const [state, setState] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [error, setError] = React.useState(false);
  const [availableError, setAvailableError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const userState = useUserState();
  const userDispatch = useUserDispatch();

  if (error && !availableError) {
    if (RegExp(usernameRegex).test(username)) {
      setError(false);
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUsername(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const usernameValidated = RegExp(usernameRegex).test(username);

    if (!usernameValidated) {
      setError(true);
      setHelperText("Invalid username.");
      return;
    }

    const fetchServiceInstance = new FetchService();
    setError(false);

    /**
     * First check if the username is available.
     */
    setLoading(true);
    let response = await fetchServiceInstance.get("/auth/available", [
      { id: "username", value: username },
    ]);

    if (response.errors) {
      setAvailableError(true);
      setHelperText(
        "We couldn't validate this username. Please try again later."
      );
      setLoading(false);
      return;
    } else if (!response.data.usernameAvailable) {
      setAvailableError(true);
      setHelperText(
        "This username is already being used. Please use a different one."
      );
      setLoading(false);
      return;
    }

    /**
     * The username must be available.
     * Send a put request to change the username.
     */
    response = await fetchServiceInstance.put(
      "/user/edit",
      {
        username,
      },
      userState?.jid
    );
    setLoading(false);

    if (response.errors) {
      setAvailableError(true);
      setHelperText(
        "There was an error changing your username. Please try again later."
      );
    } else {
      userDispatch({ type: "username", payload: username });
    }
    setState("/account/edit-profile");
  };
  if (state !== "") return <Redirect to={state} />;
  else
    return (
      <Page>
        <PageContainer>
          <button onClick={() => setState("/account/edit-profile")}>
            <BackButton
              iconProps={{ icon: faAngleRight, flip: "horizontal", size: "lg" }}
            />
          </button>
          <form onSubmit={handleSubmit}>
            <Title>Change your username</Title>
            <SubTitle>
              Your username can consist of letters and numbers.
            </SubTitle>
            <WhiteTextField
              id="username"
              label="Username"
              type="text"
              fullWidth
              size="small"
              onChange={handleInputChange}
              error={error || availableError}
              helperText={error || availableError ? helperText : ""}
            />
            <ButtonFlex>
              <Button loading={loading}>Submit</Button>
            </ButtonFlex>
          </form>
        </PageContainer>
      </Page>
    );
};

export default Username;
