import { Variants } from "framer-motion";

export const pageTransition: Variants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: (x: number) => ({
    opacity: 0,
    x: `${x * 100}vw`,
  }),
};
