import styled from "styled-components";
import { BackDropProps } from "./types";

const BackDrop = styled.div<BackDropProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.active ? "block" : "none")};
  z-index: 1000;
  background: ${(props) => (props.active ? "rgba(0, 0, 0, 0.2)" : "none")};
`;

export default BackDrop;
