import React from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import { Redirect } from "react-router-dom";
import { useUserState } from "../../context/UserContext";
import { useProfileState } from "../../context/ProfileContext";
import { DataProps } from "./type";
import { Page } from "./styles";
import ProfilePictureEditor from "../../components/ProfilePictureEditor";
import { Popper } from "@material-ui/core";
import Pfp from "../../assets/images/billboard-pfp.png";
import { LeftFlex } from "../../components/DefaultThemeCard/styles";

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 30px;
`;

const PictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PictureCircle = styled.div`
  border-radius: 100%;
  width: 90px;
  height: 90px;
  background-color: white;
  box-shadow: 0px 13px 30px 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  z-index: 0;
  overflow: hidden;
  border: 5px solid ${(props) => props.theme.colors.primary};
`;

const Picture = styled.img`
  width: 100%;
  width: 100%;
  z-index: 0;
`;

const ChangePictureButton = styled.button`
  color: ${(props) => props.theme.colors.primary};
  margin-top: 15px;
  font-weight: 800;
  margin-bottom: 40px;
  cursor: pointer;
`;

const Table = styled.table`
  border-top: 1px solid ${(props) => props.theme.colors.lightGray};
  width: 100%;
`;

const ButtonWithMargin = styled(Button)`
  margin-top: 20px;
`;

const Row = styled.tr`
  padding: 20px;
  cursor: pointer;

  border-top: 1px solid ${(props) => props.theme.colors.lightGray};
`;

const Data = styled.td<DataProps>`
  padding: 20px 0px;
  color: ${(props) => (props.empty ? "gray" : "black")};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  color: white;
`;

const DataTitle = styled.td`
  padding: 20px 0px;
  color: white;
`;

const EditProfile: React.FC = () => {
  const userState = useUserState();
  const profileState = useProfileState();

  const [state, setState] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  let email = userState?.email ? userState.email : "Email";
  let username = userState?.username ? userState.username : "Username";
  let name = profileState?.name ? profileState.name : "Name";
  let title = profileState?.title ? profileState.title : "Title";
  let bio = profileState?.bio ? profileState.bio : "Bio";
  const password = "**************";

  let profilePicture = Pfp;

  if (profileState) {
    if (profileState.picture && profileState.picture.length > 0) {
      profilePicture =
        profileState?.picture + `?lastmod=${new Date().toTimeString()}`;
    }
  }

  if (state !== "") return <Redirect to={state} />;
  else
    return (
      <Page>
        <PageContainer>
          <PictureContainer>
            <PictureCircle>
              <Picture src={profilePicture} />
            </PictureCircle>
            <ChangePictureButton aria-describedby={id} onClick={handleClick}>
              Change profile picture
            </ChangePictureButton>
            <Popper id={id} open={open} anchorEl={anchorEl}>
              <ProfilePictureEditor onClose={() => setAnchorEl(null)} />
            </Popper>
          </PictureContainer>
          <Table>
            <tbody>
              <Row onClick={() => setState("/account/edit-profile/name")}>
                <DataTitle>Name</DataTitle>
                <Data empty={!profileState?.name}>{name}</Data>
              </Row>
              <Row onClick={() => setState("/account/edit-profile/title")}>
                <DataTitle>Title</DataTitle>
                <Data empty={!profileState?.title}>{title}</Data>
              </Row>
              {/* <Row onClick={() => setState("/account/edit-profile/username")}>
              <DataTitle>Username</DataTitle>
              <Data empty={!userState?.username}>{username}</Data>
            </Row> */}
              <Row onClick={() => setState("/account/edit-profile/bio")}>
                <Data empty={false}>Bio</Data>
                <Data empty={!profileState?.bio}>{bio}</Data>
              </Row>
              <Row onClick={() => setState("/account/edit-profile/email")}>
                <DataTitle>Email</DataTitle>
                <Data empty={!userState?.email}>{email}</Data>
              </Row>
              <Row onClick={() => setState("/account/edit-profile/password")}>
                <Data empty={false}>Password</Data>
                <Data empty={false}>{password}</Data>
              </Row>
            </tbody>
          </Table>
          <ButtonWithMargin onClick={() => setState(`/${username}`)}>
            Done
          </ButtonWithMargin>
        </PageContainer>
      </Page>
    );
};

export default EditProfile;
