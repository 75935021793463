import styled from "styled-components";
import Button from "../../components/Button";
import { Theme } from "./types";

export const Page = styled.div<Pick<Theme, "background">>`
  background-image: linear-gradient(
    to bottom,
    #${(props) => props.background.color1},
    #${(props) => props.background.color2}
  );
  min-height: 100vh;
  z-index: -2;
`;

export const UpperContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 30px 30px 0px 30px;
`;

export const LowerContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 0px 30px 30px 30px;
  z-index: 2000;
`;

export const HelpText = styled.p<Pick<Theme, "buttonBackground">>`
  color: #${(props) => props.buttonBackground.color1};
  text-align: center;
  width: 100%;
  font-size: 0.9rem;
  margin-bottom: 10px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
`;

export const ButtonFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const PictureCircle = styled.div<Pick<Theme, "bumpCountBackground">>`
  border-radius: 100%;
  width: 90px;
  height: 90px;
  background-color: white;
  box-shadow: 0px 13px 30px 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  z-index: 1;
  overflow: hidden;
  border: 5px solid #${(props) => props.bumpCountBackground.color1};
`;

export const Picture = styled.img`
  width: 100%;
  height: 100%;
`;

export const NameText = styled.h1<Pick<Theme, "infoText">>`
  font-weight: 600;
  font-size: 1.4rem;
  color: #${(props) => props.infoText.color1};
  margin-bottom: 5px;
  z-index: 1;
  text-align: center;
`;

export const TitleText = styled.p<Pick<Theme, "infoText">>`
  font-size: 0.8rem;
  color: #${(props) => props.infoText.color1};
  margin-bottom: 20px;
  z-index: 1;
  text-align: center;
`;

export const BioText = styled.p<Pick<Theme, "infoText">>`
  font-size: 0.9rem;
  color: #${(props) => props.infoText.color1};
  margin-bottom: 20px;
  z-index: 1;
  text-align: center;
`;

export const BumpBubble = styled.div<Pick<Theme, "bumpCountBackground">>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-image: linear-gradient(
    to right,
    #${(props) => props.bumpCountBackground.color1},
    #${(props) => props.bumpCountBackground.color2}
  );
  padding: 10px 20px;
  margin-bottom: 20px;
  z-index: 1;
`;

export const BumpText = styled.h3<Pick<Theme, "bumpCountText">>`
  font-weight: 600;
  font-size: 0.8rem;
  color: #${(props) => props.bumpCountText.color1};
`;

export const SmallButton = styled(Button)<
  Pick<Theme, "buttonText" | "buttonBackground">
>`
  padding: 10px 15px;
  font-size: 13px;
  background-image: linear-gradient(
    to right,
    #${(props) => props.buttonBackground.color1},
    #${(props) => props.buttonBackground.color2}
  );
  color: #${(props) => props.buttonText.color1};
  z-index: 1;
`;

export const Divider = styled.div`
  width: 20px;
`;

export const ButtonWithShadow = styled(SmallButton)`
  box-shadow: 0 0.25em 0.375em rgba(50, 50, 93, 0.09),
    0 0.263em 0.488em rgba(0, 0, 0, 0.4);
  z-index: 1;
`;
