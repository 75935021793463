import React from "react";
import ThemeListUI from "./ThemeListUI";
import { useUserState } from "../../context/UserContext";
import FetchService from "../../utils/fetchService";
import { Theme } from "../../types/themes";

const ThemeList: React.FC = () => {
  const [themes, setThemes] = React.useState<Theme[] | null>(null);
  // const [error, setError] = React.useState(false);
  const [activeThemeId, setActiveThemeId] = React.useState<number | null>(null);
  const [usingDefaultTheme, setUsingDefaultTheme] = React.useState<
    boolean | null
  >(null);
  const [selectedDefaultTheme, setSelectedDefaultTheme] = React.useState<
    string | null
  >(null);

  const userState = useUserState();

  React.useEffect(() => {
    // setError(false);
    async function fetch() {
      const fetchService = new FetchService();
      const result = await fetchService.get(
        `/theme/list`,
        undefined,
        userState?.jid
      );

      if (result.data) {
        setThemes(result.data.themes);
        setActiveThemeId(result.data.themeSettings.activeThemeId);
        setUsingDefaultTheme(result.data.themeSettings.usingDefaultTheme);
        setSelectedDefaultTheme(result.data.themeSettings.selectedDefaultTheme);
      } else {
        // setError(true);
      }
    }

    fetch();
  }, [userState]);

  const handleThemeSelect = async (id: number) => {
    setActiveThemeId(id);
    setUsingDefaultTheme(false);
    const fetchService = new FetchService();
    fetchService.put(
      "/theme/active",
      {
        usingDefaultTheme: false,
        selectedDefaultTheme: "jetBlack",
        activeThemeId: id,
      },
      userState?.jid
    );
  };

  const handleDefaultSelect = async (name: string) => {
    setActiveThemeId(null);
    setUsingDefaultTheme(true);
    setSelectedDefaultTheme(name);
    const fetchService = new FetchService();
    fetchService.put(
      "/theme/active",
      {
        usingDefaultTheme: true,
        selectedDefaultTheme: name,
      },
      userState?.jid
    );
  };

  return (
    <ThemeListUI
      themes={themes || []}
      activeThemeId={activeThemeId || -1}
      usingDefaultTheme={usingDefaultTheme || false}
      selectedDefaultTheme={selectedDefaultTheme || "jetBlack"}
      onSelect={handleThemeSelect}
      onDefaultSelect={handleDefaultSelect}
    />
  );
};

export default ThemeList;
