import React, { createContext } from "react";
import LocalStorageService from "../utils/localStorage";
import { Profile } from "./types";

type Dispatch = (action: Action) => void;

type Action =
  | { type: "login"; payload: State }
  | { type: "logout" }
  | { type: "name"; payload: string }
  | { type: "bio"; payload: string }
  | { type: "picture"; payload: string }
  | { type: "title"; payload: string };

interface State extends Profile {}

type CountProviderProps = { children: React.ReactNode };
const ProfileStateContext = createContext<State | null>(null);
const ProfileDispatchContext = createContext<Dispatch>(() => {});

const localStorageInstance = new LocalStorageService();

const profileReducer = (state: State | null, action: Action) => {
  switch (action.type) {
    case "login": {
      localStorageInstance.save("profile", action.payload);
      return action.payload;
    }

    case "name": {
      if (state) {
        const newProfile = {
          ...state,
          name: action.payload,
        };

        localStorageInstance.save("profile", newProfile);
        return newProfile;
      } else {
        return null;
      }
    }

    case "bio": {
      if (state) {
        const newProfile = {
          ...state,
          bio: action.payload,
        };

        localStorageInstance.save("profile", newProfile);
        return newProfile;
      } else {
        return null;
      }
    }

    case "logout": {
      localStorageInstance.clearStorage();
      return null;
    }

    case "picture": {
      if (state) {
        const newProfile = {
          ...state,
          picture: action.payload,
        };

        localStorageInstance.save("profile", newProfile);
        return newProfile;
      } else {
        return null;
      }
    }

    case "title": {
      if (state) {
        const newProfile = {
          ...state,
          title: action.payload,
        };

        return newProfile;
      } else {
        return null;
      }
    }

    default: {
      throw new Error("Unhandled action type");
    }
  }
};

const ProfileProvider = ({ children }: CountProviderProps) => {
  const defaultProfileData = localStorageInstance.load("profile") || null;
  const [state, dispatch] = React.useReducer(
    profileReducer,
    defaultProfileData
  );

  return (
    <ProfileStateContext.Provider value={state}>
      <ProfileDispatchContext.Provider value={dispatch}>
        {children}
      </ProfileDispatchContext.Provider>
    </ProfileStateContext.Provider>
  );
};

function useProfileState() {
  const context = React.useContext(ProfileStateContext);
  return context;
}

function useProfileDispatch() {
  const context = React.useContext(ProfileDispatchContext);
  return context;
}
export { ProfileProvider, useProfileState, useProfileDispatch };
