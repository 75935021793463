import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { FormStep } from "./types";
import Email from "./Email";
import Username from "./Username";
import Password from "./Password";
import FetchService from "../../utils/fetchService";
import { useHistory } from "react-router-dom";
import { useUserDispatch } from "../../context/UserContext";
import { useProfileDispatch } from "../../context/ProfileContext";
import { LoginText } from "./styles";
import { Link } from "react-router-dom";
import useQuery from "../../utils/useQuery";
import RegisterCode from "./RegisterCode";

const Page = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  width: 100%;
`;

const PageContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 50px 40px;
  background-color: ${(props) => props.theme.colors.background};
`;

// eslint-disable-next-line no-control-regex
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const usernameRegex = /^[a-zA-Z0-9_-]{3,15}$/;
// const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*)])(?=.{8,})/;

const Signup = () => {
  const query = useQuery();
  const codeQuery = query.get("code");
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [emailAvailableError, setEmailAvailableError] = React.useState(false);
  const [emailHelperText, setEmailHelperText] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [usernameError, setUsernameError] = React.useState(false);
  const [code, setCode] = React.useState(codeQuery || "");
  const [codeError, setCodeError] = React.useState(false);
  const [codeHelperText, setCodeHelperText] = React.useState("");
  const [usernameHelperText, setUsernameHelperText] = React.useState("");
  const [usernameAvailableError, setUsernameAvailableError] = React.useState(
    false
  );
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
  const [formStep, setFormStep] = React.useState(FormStep.REGISTER_CODE);
  const [loading, setLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);
  const [direction, setDirection] = React.useState<"left" | "right">("right");
  const history = useHistory();

  const userDispatch = useUserDispatch();
  const profileDispatch = useProfileDispatch();

  if (emailError && !emailAvailableError) {
    if (RegExp(emailRegex).test(email)) {
      setEmailError(false);
    }
  }

  if (usernameError && !usernameAvailableError) {
    if (RegExp(usernameRegex).test(username)) {
      setUsernameError(false);
    }
  }

  if (passwordError) {
    if (password.length > 8) {
      setPasswordError(false);
    }
  }

  if (confirmPasswordError) {
    if (password === confirmPassword) {
      setConfirmPasswordError(false);
    }
  }

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: FormStep
  ) => {
    switch (type) {
      case FormStep.REGISTER_CODE:
        setCode(event.target.value);
        setCodeError(false);
        break;
      case FormStep.EMAIL:
        setEmail(event.target.value);
        setEmailAvailableError(false);
        break;
      case FormStep.USERNAME:
        setUsername(event.target.value);
        setUsernameAvailableError(false);
        break;
      case FormStep.PASSWORD:
        setPassword(event.target.value);
        break;
      case FormStep.CONFIRM_PASSWORD:
        setConfirmPassword(event.target.value);
        break;
    }
  };

  const handleFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    type: FormStep
  ) => {
    setDirection("right");
    event.preventDefault();
    const fetchServiceInstance = new FetchService();
    let response = null;

    switch (type) {
      case FormStep.REGISTER_CODE:
        setCodeError(false);
        setLoading(true);
        response = await fetchServiceInstance.get(`/reroute/${code}`);
        setLoading(false);

        if (response.errors) {
          setCodeError(true);
          setCodeHelperText("We could not validate this register code.");
        } else if (
          response.data.reroute.activated ||
          response.data.reroute.username
        ) {
          setCodeError(true);
          setCodeHelperText("This register code has already been used.");
        } else {
          setFormStep(formStep + 1);
        }
        break;
      case FormStep.EMAIL:
        const emailValidated = RegExp(emailRegex).test(email);

        if (emailValidated) {
          setEmailError(false);
          setLoading(true);
          response = await fetchServiceInstance.get("/auth/available", [
            { id: "email", value: email.toLowerCase() },
          ]);
          setLoading(false);

          if (response.errors) {
            setEmailAvailableError(true);
            setEmailHelperText(
              "We couldn't validate this email address. Please try again later."
            );
          } else if (!response.data.emailAvailable) {
            setEmailAvailableError(true);
            setEmailHelperText(
              "This email address is already being used. Please use a different one."
            );
          } else {
            setFormStep(formStep + 1);
          }
        } else {
          setEmailError(true);
          setEmailHelperText("Invalid email address.");
        }
        break;
      case FormStep.USERNAME:
        const usernameValidated = RegExp(usernameRegex).test(username);
        const usernameHasSpaces = username.indexOf(' ') >= 0;
        
        if (usernameValidated && !usernameHasSpaces) {
          setUsernameError(false);
          setLoading(true);
          response = await fetchServiceInstance.get("/auth/available", [
            { id: "username", value: username.toLowerCase() },
          ]);
          setLoading(false);

          if (response.errors) {
            setUsernameAvailableError(true);
            setUsernameHelperText(
              "We couldn't validate this username. Please try again later."
            );
          } else if (!response.data.usernameAvailable) {
            setUsernameAvailableError(true);
            setUsernameHelperText(
              "This username is already being used. Please use a different one."
            );
          } else {
            setFormStep(formStep + 1);
          }
        } else {
          setUsernameError(true);
          if (usernameHasSpaces)
            setUsernameHelperText("Username cannot have spaces.");
          else
            setUsernameHelperText("Invalid username.");
        }
        break;
      case FormStep.PASSWORD:
        setSubmitError(false);
        const passwordValidated = password.length > 8;
        const confirmPasswordValidated = confirmPassword === password;

        if (passwordValidated) {
          setPasswordError(false);
          if (confirmPasswordValidated) {
            setConfirmPasswordError(false);
            setLoading(true);
            const fetchServiceInstance = new FetchService();
            const response = await fetchServiceInstance.post("/auth/register", {
              username,
              email,
              password,
              confirmPassword,
            });
            setLoading(false);

            if (response.errors) {
              setSubmitError(true);
            } else {
              const {
                active,
                bio,
                name,
                picture,
                title,
              } = response.data.profile;
              const { username, email } = response.data.user;
              const jid = response.data.token;

              // Activate the user's code
              await fetchServiceInstance.put("/reroute/activate-code", {
                code,
                username,
              });

              const userPayload = {
                username,
                email,
                jid,
              };
              const profilePayload = {
                active,
                bio,
                name,
                picture,
                title: title || "",
              };

              userDispatch({ type: "login", payload: userPayload });
              profileDispatch({ type: "login", payload: profilePayload });
              history.push("/account/edit-profile");
            }
          } else {
            setConfirmPasswordError(true);
          }
        } else {
          setPasswordError(true);
        }
        break;
    }
  };

  const handleBackButton = (type: FormStep) => {
    setDirection("left");
    switch (type) {
      case FormStep.REGISTER_CODE:
        setFormStep(formStep);
        break;
      case FormStep.USERNAME:
      case FormStep.EMAIL:
      case FormStep.PASSWORD:
        setFormStep(formStep - 1);
        break;
    }
  };

  let ActiveForm = null;

  switch (formStep) {
    case FormStep.REGISTER_CODE:
      ActiveForm = (
        <RegisterCode
          onFormSubmit={handleFormSubmit}
          onInputChange={handleInputChange}
          onBackButton={handleBackButton}
          value={code}
          error={codeError}
          loading={loading}
          helperText={codeHelperText}
          direction={direction}
        />
      );
      break;
    case FormStep.EMAIL:
      ActiveForm = (
        <Email
          onFormSubmit={handleFormSubmit}
          onInputChange={handleInputChange}
          onBackButton={handleBackButton}
          value={email}
          error={emailError || emailAvailableError}
          loading={loading}
          helperText={emailHelperText}
          direction={direction}
        />
      );
      break;
    case FormStep.USERNAME:
      ActiveForm = (
        <Username
          onFormSubmit={handleFormSubmit}
          onInputChange={handleInputChange}
          onBackButton={handleBackButton}
          value={username}
          error={usernameError || usernameAvailableError}
          loading={loading}
          helperText={usernameHelperText}
          direction={direction}
        />
      );
      break;
    case FormStep.PASSWORD:
      ActiveForm = (
        <Password
          onFormSubmit={handleFormSubmit}
          onInputChange={handleInputChange}
          onBackButton={handleBackButton}
          value={password}
          confirmValue={confirmPassword}
          error={passwordError}
          submitError={submitError}
          confirmError={confirmPasswordError}
          loading={loading}
          direction={direction}
        />
      );
  }

  return (
    <Page>
      <PageContainer>{ActiveForm}</PageContainer>
      <LoginText>
        Already have an account? Login{" "}
        <Link
          style={{ color: "#11B8FF" }}
          to={`/account/login${codeQuery ? "?code=" + codeQuery : ""}`}
        >
          here
        </Link>
        .
      </LoginText>
    </Page>
  );
};

export default Signup;
