import React from "react";
import { Page } from "../../pages/EditProfile/styles";
import TopNav from "../../components/TopNav";
import styled from "styled-components";
import Bill404 from "../../assets/images/billboard-404.svg";

const PageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
`;

const Title = styled.h1`
  font-family: averta-extrabold;
  font-size: 1.5rem;
  color: white;
  margin-bottom: 20px;
  text-align: center;
`;

const Img = styled.img`
  width: 200px;
  height: 200px;
`;

const NotFound: React.FC<{ title: string; topNav?: boolean }> = ({
  title,
  topNav = false,
}) => {
  return (
    <Page>
      {topNav ? <TopNav /> : <div />}
      <PageContainer>
        <Title>{title}</Title>
        <Img src={Bill404} />
      </PageContainer>
    </Page>
  );
};

export default NotFound;
