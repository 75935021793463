import styled from "styled-components";
import Button from "../Button";
import { withStyles, Slider } from "@material-ui/core";

export const Container = styled.div`
  background: white;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 10px 0 0;
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

export const SaveButton = styled(Button)`
  padding: 5px 10px;
  margin-top: 20px;
`;

export const Divider = styled.div`
  height: 10px;
`;

export const UploadButton = styled(SaveButton)`
  border: 2px solid ${(props) => props.theme.colors.primary};
  margin-bottom: 10px;
`;

export const ErrorText = styled.p`
  color: red;
  font-size: 1.3rem;
  margin-top: 5px;
`;

export const CloseButon = styled.img`
  width: 30px;
  height: 30px;
  justify-content: center;
  cursor: pointer;
`;

export const PrettoSlider = withStyles({
  root: {
    color: "#11B8FF",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
