import React from "react";
import {
  PageContainer,
  Page,
  SuccessContainer,
  SuccessHeader,
  SuccessBody,
} from "./styles";

import { Title, SubTitle, ButtonFlex } from "../Signup/styles";
import WhiteTextField from "../../components/WhiteTextField";
import Button from "../../components/Button";
import FetchService from "../../utils/fetchService";
import { useUserState } from "../../context/UserContext";
import useQuery from "../../utils/useQuery";

const Activate: React.FC = () => {
  const query = useQuery();
  const codeQuery = query.get("code");
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(codeQuery || "");
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const userState = useUserState();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(false);
    setLoading(true);
    const fetchServiceInstance = new FetchService();
    const response = await fetchServiceInstance.put("/reroute/activate-code", {
      code: value,
      username: userState?.username,
    });

    if (response.errors) {
      setError(true);
      setHelperText("This register code has already been used.");
    } else {
      setSuccess(true);
    }
    setLoading(false);
  };

  return (
    <Page>
      <PageContainer>
        <form onSubmit={handleFormSubmit}>
          <Title>Activate new uBump</Title>
          <SubTitle>
            Bump your phone on your new uBump to receive your access code.
          </SubTitle>
          <WhiteTextField
            id="register-code"
            label="Access code"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            fullWidth
            size="small"
            error={error}
            helperText={error ? helperText : ""}
          />
          <ButtonFlex>
            <Button loading={loading}>Activate</Button>
          </ButtonFlex>
        </form>
        {success ? (
          <SuccessContainer>
            <SuccessHeader>Success!</SuccessHeader>
            <SuccessBody>
              Your new uBump has been activated and connected to your profile!
            </SuccessBody>
          </SuccessContainer>
        ) : (
          <div />
        )}
      </PageContainer>
    </Page>
  );
};

export default Activate;
