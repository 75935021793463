import React from "react";
import { LinkChangeProps } from "./types";
import selectIcon from "../../utils/iconSelector";
import styled from "styled-components";
import { TextField, withStyles } from "@material-ui/core";
import Button from "../Button";
import FetchService from "../../utils/fetchService";
import { useUserState } from "../../context/UserContext";
import { LinkType } from "../../pages/Profile/types";
import VCardForm from "../VCardForm";
import vCardJs from "vcards-js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const LinkIcon = styled.img`
  margin-top: 30px;
  width: 70px;
  height: 70px;
`;

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#4A67B1",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4A67B1",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "#4A67B1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4A67B1",
      },
    },
  },
})(TextField);

const ButtonWithMargin = styled(Button)`
  margin-top: 35px;
  margin-bottom: 10px;
  padding: 20px;
  padding: 10px 20px;
  cursor: pointer;
`;

const DeleteButton = styled(Button)`
  background-color: red;
  padding: 10px 20px;
  cursor: pointer;
  width: calc(100% - 20px);
`;

const Divider = styled.div`
  height: 10px;
`;

const ErrorText = styled.p`
  color: red;
`;

const Form = styled.form`
  width: 100%;
  padding: 10px;
`;

const LinkChange: React.FC<LinkChangeProps> = ({
  id,
  type,
  order,
  job,
  onComplete,
  onDelete,
  label,
  editTitle,
  editPath,
  onValueChange,
}) => {
  const [titleValue, setTitleValue] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [businessName, setBusinessName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [pathValue, setPathValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [error, setError] = React.useState(false);

  const userState = useUserState();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "title" | "path"
  ) => {
    if (onValueChange) {
      onValueChange(event, type);
      return;
    }

    if (type === "title") {
      setTitleValue(event.target.value);
    } else if (type === "path") {
      setPathValue(event.target.value);
    }
  };

  const handleDelete = async () => {
    if (onDelete) {
      setError(false);
      const fetchServiceInstance = new FetchService();

      setLoadingDelete(true);
      const result = await fetchServiceInstance.delete(
        "/link/delete",
        {
          id,
        },
        userState?.jid
      );

      if (result.errors) {
        setError(true);
        return;
      }
      setLoadingDelete(false);

      onDelete(id);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setError(false);
    setLoading(true);

    let postPathValue = pathValue;

    if (type === "contact") {
      const vCard = vCardJs();
      vCard.firstName = firstName;
      vCard.lastName = lastName;
      vCard.cellPhone = phoneNumber;
      vCard.email = email;
      vCard.organization = businessName;

      postPathValue = vCard.getFormattedString();
    }

    const fetchServiceInstance = new FetchService();
    let result = null;
    let link = null;
    if (job === "edit") {
      result = await fetchServiceInstance.put(
        "/link/edit",
        {
          links: [
            {
              id,
              title: editTitle ? editTitle : titleValue,
              path: editPath ? editPath : postPathValue,
            },
          ],
        },
        userState?.jid
      );

      if (result.errors) {
        setError(true);
        return;
      }
      link = result.data.links[0];
    } else if (job === "create") {
      result = await fetchServiceInstance.post(
        "/link/create",
        {
          type,
          title: titleValue,
          path: postPathValue,
          order,
        },
        userState?.jid
      );
      link = result.data.link;
    }

    setLoading(false);

    if (result.errors) {
      setError(true);
      return;
    }

    /**
     * The returned value is an array because generally,
     * we are able to edit multiple links at a time.
     */

    if (result.data) {
      onComplete({
        title: link.title as string,
        path: link.path as string,
        id: link.id as number,
        type: link.type as LinkType,
        order: 0,
      });
    }
  };

  return (
    <Container>
      <LinkIcon src={selectIcon(type)} />
      <Form onSubmit={handleSubmit}>
        <CssTextField
          label="Title"
          fullWidth
          value={editTitle !== null ? editTitle : titleValue}
          onChange={(event) => handleInputChange(event, "title")}
        />
        <Divider />
        {type === "contact" ? (
          <VCardForm
            firstName={firstName}
            lastName={lastName}
            email={email}
            phoneNumber={phoneNumber}
            businessName={businessName}
            onFirstNameChange={setFirstName}
            onLastNameChange={setLastName}
            onEmailChange={setEmail}
            onPhoneNumberChange={setPhoneNumber}
            onBusinessNameChange={setBusinessName}
          />
        ) : (
          <CssTextField
            label={label}
            fullWidth
            value={editPath !== null ? editPath : pathValue}
            onChange={(event) => handleInputChange(event, "path")}
          />
        )}
        {error ? (
          <ErrorText>
            There was an error editing your link. Please try again later.
          </ErrorText>
        ) : (
          <div />
        )}
        <ButtonWithMargin bubble loading={loading}>
          Submit
        </ButtonWithMargin>
      </Form>
      {job === "edit" ? (
        <DeleteButton onClick={handleDelete} bubble loading={loadingDelete}>
          Delete
        </DeleteButton>
      ) : (
        <div />
      )}
    </Container>
  );
};

export default LinkChange;
