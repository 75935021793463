import styled from "styled-components";
import Button from "../../components/Button";

export const Page = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
`;

export const PageContainer = styled.div`
  padding: 30px;
  max-width: 700px;
  margin: auto;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
`;

export const PageTitle = styled.h1`
  font-family: averta-regular;
  color: white;
  font-size: 1.2rem;
`;

export const NewThemeButton = styled(Button)`
  padding: 5px 15px;
  width: 150px;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ThemeTypeTitle = styled.h1`
  font-size: 1.2rem;
  font-family: averta-regular;
  color: white;
  margin-bottom: 10px;
`;
